<template>
  <el-dialog :title="$t('price-change.detected')"
    :visible="price !== null"
    :before-close="handleClose">
    <el-table :data="rateChangesTableData"
      cell-class-name="white-space-nowrap width-fit"
      class="full-width">
      <el-table-column type="index"
        :index="(index) => rateChangesTableData[index].index"
        width="250px"
        min-width="fit-content"
        class="white-space-nowrap" />
      <el-table-column prop="amount"
        label="Amount" />
      <el-table-column prop="change"
        label="Change">
        <template slot-scope="scope">
          <span>{{ scope.row.change || '-' }}</span>
          <span v-if="scope.row.change"
            class="fas ml-1 fs-1-4rem"
            :class="scope.row.change.charAt(0) === '-' ? 'fa-circle-arrow-down color-success' : 'fa-circle-arrow-up color-warning'" />
        </template>
      </el-table-column>
    </el-table>

    <!-- Price Change Summary -->
    <el-alert type="info"
      :closable="false"
      center
      class="my-2 py-0 px-1">
      <template v-if="isNewNewPricing || isCXCtoVXC">
        <p>{{ $t('price-change.pricing-model') }}</p>
        <p v-html="priceChange1" /> <!-- eslint-disable-line vue/no-v-html -->
        <p>{{ $t('price-change.confirm-model-change') }}</p>
      </template>
      <template v-else>
        <p v-html="priceChange2" /> <!-- eslint-disable-line vue/no-v-html -->
        <p>{{ $t('pricebook.price-change-3', { longTermMonthlyPrice:longTermMonthlyPrice }) }}</p>
      </template>
    </el-alert>

    <!-- Legal Paragraph -->
    <div class="warning-message"
      role="alert">
      <strong class="d-block mb-2">{{ $t('general.important-information') }}</strong>
      <div v-html="legalParagraph" /> <!-- eslint-disable-line vue/no-v-html -->
    </div>

    <!-- Action Buttons -->
    <template #footer>
      <el-button data-name="cancelRateChange"
        @click="$emit('close')">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button type="primary"
        data-name="acceptRateChange"
        @click="$emit('accept')">
        {{ $t('general.confirm') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
// External Tools
import { mapGetters } from 'vuex'
import { isNumber } from 'lodash'
// Internal Tools
import { moneyFilter } from '@/helpers.js'
// Globals
import { LEGAL_PARAGRAPH_MANAGED_PARTNER, LEGAL_PARAGRAPH_MANAGED_USER } from '@/Globals.js'

export default {
  props: {
    price: {
      type: Object,
      default: null,
    },
    /** START **/
    // Part of a workaround to display the current recurring monthly rate in PriceChange (see ENG-21893)
    // API: We need GET /v2/product/:productUid/rating/:year/:month?newSpeed=<speed> to include the current recurring monthly rate (see ENG-21967)
    // TODO: Remove this as part of the necessary refactor in ENG-21968
    currentMonthlyPrice: {
      type: Number,
      default: null,
    },
    /** END **/
  },

  computed: {
    ...mapGetters('Auth', ['isPartnerAccount', 'isManagedAccount']),

    priceChange1() {
      return this.$t('pricebook.price-change-1', {
        currentMonthlyPrice: this.formattedCurrentMonthlyPrice,
        totalPrice: this.totalPrice,
        changeDirectionText: this.changeDirection,
        deltaPrice: this.deltaPrice,
        longTermMonthlyPrice: this.longTermMonthlyPrice,
      })
    },
    priceChange2() {
      return this.$t('pricebook.price-change-2', {
        totalPrice: this.totalPrice,
        changeDirectionText: this.changeDirection,
        deltaPrice: this.deltaPrice,
      })
    },
    isCXCtoVXC() {
      if (!this.price) return false

      return this.price.forceProductChange === 'CXC_TO_VXC'
    },
    isNewNewPricing() {
      if (!this.price) return false

      return this.price.forceProductChange === 'VXC_TO_NEW_PRICING'
    },
    deltaPrice() {
      if (this.price && isNumber(this.price.delta) && this.price.currency) {
        return moneyFilter(this.price.delta, this.price.currency)
      }

      return ''
    },
    totalPrice() {
      if (this.price && isNumber(this.price.total) && this.price.currency) {
        return moneyFilter(this.price.total, this.price.currency)
      }

      return ''
    },
    longTermMonthlyPrice() {
      if (this.price && isNumber(this.price.longTermMonthly) && this.price.currency) {
        return moneyFilter(this.price.longTermMonthly, this.price.currency)
      }

      return ''
    },
    /** START **/
    // Part of a workaround to display the current recurring monthly rate in PriceChange (see ENG-21893)
    // API: We need GET /v2/product/:productUid/rating/:year/:month?newSpeed=<speed> to include the current recurring monthly rate (see ENG-21967)
    // TODO: Rename this to currentMonthlyPrice and change to use price.nameOfTheNewField instead as part of the necessary refactor in ENG-21968
    formattedCurrentMonthlyPrice() {
      if (this.currentMonthlyPrice && isNumber(this.currentMonthlyPrice) && this.price?.currency) {
        return moneyFilter(this.currentMonthlyPrice, this.price.currency)
      }

      return ''
    },
    /** END **/
    monthlyDeltaPrice() {
      if (this.price && isNumber(this.price.longTermMonthly) && isNumber(this.price.total) && isNumber(this.price.delta) && this.price.currency) {
        return moneyFilter(this.price.longTermMonthly - this.currentMonthlyPrice, this.price.currency)
      }

      return ''
    },
    changeDirection() {
      if (this.price && isNumber(this.price.delta)) {
        return this.price.delta > 0 ? this.$t('price-change.increase') : this.$t('price-change.decrease')
      }

      return ''
    },
    legalParagraph() {
      if (this.isPartnerAccount) return LEGAL_PARAGRAPH_MANAGED_PARTNER

      if (this.isManagedAccount) return LEGAL_PARAGRAPH_MANAGED_USER

      return process.env.VUE_APP_LEGAL_PARAGRAPH
    },
    rateChangesTableData() {
      return [
        {
          index: this.$t('price-change.current-monthly'),
          amount: this.formattedCurrentMonthlyPrice,
          change: null,
        },
        {
          index: this.$t('price-change.new-monthly'),
          amount: this.longTermMonthlyPrice,
          change: this.monthlyDeltaPrice,
        },
        {
          index: this.$t('price-change.this-period'),
          amount: this.totalPrice,
          change: this.deltaPrice,
        },
      ]
    },
  },

  methods: {
    handleClose(done) {
      this.$emit('close')

      done()
    },
  },
}
</script>

<style lang="scss" scoped>
.max-width-520px {
  max-width: 520px;
}

.warning-message {
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 1rem 2rem;
  word-break: normal;
  word-wrap: break-word;
}
</style>
