<template>
  <div class="detail-table">
    <!-- Service ID -->
    <simple-read-only-field v-if="service.productUid"
      :label="$t('services.service-id')"
      :value="`#${service.productUid.slice(0, 8)}`"
      data-testid="service-id" />

    <!-- Sub-title: Service Status -->
    <h4 class="mt-2 mb-1 text-align-center font-weight-500">
      {{ $t('services.service-status') }}
    </h4>

    <!-- Provisioning Status -->
    <simple-read-only-field :label="$t('services.provisioning-status')"
      :value="service.provisioningStatus"
      data-testid="provisioning-status" />

    <!-- Service Status -->
    <simple-read-only-field :label="$t('services.service-status')"
      data-testid="service-status">
      <!-- Status Info Popover -->
      <el-popover width="fit-content"
        placement="top"
        :open-delay="500"
        trigger="hover">
        <!-- Status Info Popover Content -->
        <div class="text-align-left fs-1-4rem p-1">
          <!-- Message -->
          <p class="text-align-center my-0">
            <strong>{{ statusInfo.message }}</strong>
          </p>
          <!-- BGP Statuses -->
          <template v-if="statusInfo.bgpStatuses.length">
            <p class="mt-2 mb-1">
              <strong>{{ $t('connections.bgp-statuses') }}</strong>
            </p>
            <div v-for="(status, index) in statusInfo.bgpStatuses"
              :key="index"
              class="flex-row-centered position-relative">
              <div class="service-status-icon"
                :class="status.status" />
              <p class="ml-1 my-0-5">
                {{ status.message }}
              </p>
            </div>
          </template>
        </div>
        <!-- Status Info Popover Reference (Icon) -->
        <div slot="reference"
          class="service-status-icon mt-0-25"
          :class="statusInfo.status" />
      </el-popover>
    </simple-read-only-field>

    <!-- BGP Statuses -->
    <simple-read-only-field v-for="(status, index) in statusInfo.bgpStatuses"
      :key="`bgp-status-${index}`"
      :label="`${$t('services.bgp')} ${status.message}`"
      :data-testid="`bgp-status-${index + 1}`">
      <el-tooltip placement="top"
        :content="status.statusMessage"
        :open-delay="500">
        <div class="service-status-icon mt-0-25"
          :class="status.status" />
      </el-tooltip>
    </simple-read-only-field>

    <!-- Sub-title: Service Information -->
    <h4 class="mt-2 mb-1 text-align-center font-weight-500">
      {{ $t('services.service-information') }}
    </h4>

    <!-- Created By -->
    <simple-read-only-field v-if="createdByUser"
      :label="$t('general.created-by')"
      :value="createdByUser.name"
      :value-tooltip="createdByUser.email"
      data-testid="created-by" />

    <template v-if="serviceIsIx">
      <!-- IX: Rate Limit -->
      <simple-read-only-field v-if="service.resources"
        :label="$t('services.rate-limit')"
        :value="`${service.resources.vpls_interface.rate_limit_mbps} Mbps`"
        data-testid="ix-rate-limit" />

      <!-- IX: MAC Address -->
      <simple-read-only-field v-if="service.resources"
        :label="$t('connections.mac-address')"
        :value="service.resources.vpls_interface.mac_address"
        data-testid="ix-mac-address" />

      <!-- IX: VLAN -->
      <simple-read-only-field :label="$t('connections.vlan')"
        :value="[null, -1].includes(service.vlan) ? $t('connections.untagged') : service.vlan"
        data-testid="ix-vlan" />

      <!-- IX: Shutdown -->
      <simple-read-only-field v-if="service.resources && service.resources.vpls_interface"
        :label="$t('services.type-state', { type: $t('productNames.ix') })"
        :value="$t(service.resources.vpls_interface.shutdown ? 'general.shut-down' : 'general.enabled')"
        data-testid="ix-shutdown" />

      <!-- IX: Customer ASN -->
      <simple-read-only-field v-if="service.resources && service.resources.bgp_connection"
        :label="$t('connections.customer-asn')"
        :value="service.resources.bgp_connection[0].customer_asn"
        data-testid="ix-customer-asn" />

      <!-- IX: IPv4 Address -->
      <simple-read-only-field v-if="service.resources"
        :label="$t('general.ipv4')"
        :value="ipv4Address"
        data-testid="ix-ipv4-address" />

      <!-- IX: IPv6 Address -->
      <simple-read-only-field v-if="service.resources"
        :label="$t('general.ipv6')"
        :value="ipv6Address"
        data-testid="ix-ipv6-address" />

      <template v-if="service.resources && service.resources.bgp_connection">
        <!-- Sub-title: Route Servers -->
        <h4 class="mt-2 mb-1 text-align-center font-weight-500">
          {{ $t('connections.route-servers') }}
        </h4>

        <!-- IX: BGP ASN -->
        <simple-read-only-field :label="$t('connections.asn')"
          :value="service.resources.bgp_connection[0].isp_asn"
          data-testid="ix-bgp-asn" />

        <!-- IX: BGP IPv4 Address -->
        <simple-read-only-field :label="$t('general.ipv4')"
          data-testid="ix-bgp-ipv4-address">
          <p v-for="(connection, index) in ipv4Connections"
            :key="`ix-bgp-ipv4-address-${index}`"
            class="m-0">
            {{ connection.isp_ip_address }}
          </p>
        </simple-read-only-field>

        <!-- IX: BGP IPv6 Address -->
        <simple-read-only-field :label="$t('general.ipv6')"
          data-testid="ix-bgp-ipv6-address">
          <p v-for="(connection, index) in ipv6Connections"
            :key="`ix-bgp-ipv6-address-${index}`"
            class="m-0">
            {{ connection.isp_ip_address }}
          </p>
        </simple-read-only-field>
      </template>
    </template>

    <template v-else>
      <template v-if="service.resources && service.resources.vll">
        <!-- Rate Limit -->
        <simple-read-only-field :label="$t('services.rate-limit')"
          :value="`${service.resources.vll.rate_limit_mbps} Mbps`"
          data-testid="rate-limit" />

        <!-- A-End VNIC -->
        <simple-read-only-field v-if="aEndIsMve && aEndOwned"
          :label="$t('connections.end-vnic', {end: 'A'})"
          :value="vNicValue('aEnd')"
          data-testid="a-end-vnic-index" />

        <!-- B-End VNIC -->
        <simple-read-only-field v-if="bEndIsMve && bEndOwned"
          :label="$t('connections.end-vnic', {end: 'B'})"
          :value="vNicValue('bEnd')"
          data-testid="b-end-vnic-index" />

        <!-- VLAN -->
        <template v-if="!cspConnection || cspConnection.connectType !== 'TRANSIT'">
          <!-- A-End VLAN -->
          <simple-read-only-field v-if="aEndOwned"
            :label="$t('connections.end-vlan', { end:'A' })"
            :value="vlanData('aEnd', aEndIsMve)"
            data-testid="a-end-vlan" />

          <!-- B-End VLAN -->
          <simple-read-only-field v-if="bEndOwned"
            :label="$t('connections.end-vlan', { end: 'B' })"
            :value="vlanData('bEnd', bEndIsMve)"
            data-testid="b-end-vlan" />
        </template>

        <!-- Azure VLAN -->
        <simple-read-only-field v-if="bEndConnectType === 'AZURE' && service.bEnd.innerVlan"
          :label="$t('connections.azure-vlan')"
          :value="service.bEnd.innerVlan"
          data-testid="azure-vlan" />

        <!-- Azure VXC Shutdown -->
        <simple-read-only-field :label="$t('services.type-state', { type: $t('productNames.vxc') })"
          :value="$t(service.resources.vll.shutdown ? 'general.shut-down' : 'general.enabled')"
          data-testid="vxc-shutdown" />
      </template>

      <template v-if="cspConnection">
        <template v-if="bEndConnectType === 'TRANSIT'">
          <!-- Megaport Internet: Public IPv4 Address -->
          <simple-read-only-field :label="$t('connections.public-ipv4')"
            :value="cspConnection.customer_ip4_address"
            data-testid="transit-ipv4-address" />

          <!-- Megaport Internet: Public IPv6 Address -->
          <simple-read-only-field :label="$t('connections.public-ipv6')"
            :value="cspConnection.customer_ip6_network"
            data-testid="transit-ipv6-network" />

          <!-- Megaport Internet: Gateway IPv4 Address -->
          <simple-read-only-field :label="$t('connections.gateway-ipv4')"
            :value="cspConnection.ipv4_gateway_address"
            data-testid="transit-ipv4-gateway" />

          <!-- Megaport Internet: Gateway IPv6 Address -->
          <simple-read-only-field :label="$t('connections.gateway-ipv6')"
            :value="cspConnection.ipv6_gateway_address"
            data-testid="transit-ipv6-gateway" />
        </template>

        <template v-if="bEndConnectType === 'AZURE'">
          <!-- Sub-title: Azure Interface -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('azure.interface') }}
          </h4>

          <!-- Azure Cloud Connection: Service Key -->
          <simple-read-only-field :label="$t('services.service-key')"
            :value="cspConnection.service_key"
            data-testid="azure-service-key" />
        </template>

        <template v-if="bEndConnectType === 'NUTANIX'">
          <!-- Sub-title: Nutanix Interface -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('nutanix.interface') }}
          </h4>

          <!-- Nutanix Cloud Connection: Service Key -->
          <simple-read-only-field :label="$t('services.service-key')"
            :value="cspConnection.serviceKey"
            data-testid="nutanix-service-key" />
        </template>

        <template v-if="bEndConnectType === 'ALIBABA'">
          <!-- Sub-title: Alibaba Connection -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('alibaba.connection') }}
          </h4>

          <!-- Alibaba Cloud Connection: Account ID -->
          <simple-read-only-field :label="$t('alibaba.account-id')"
            :value="cspConnection.account_id"
            data-testid="alibaba-account-id" />

          <!-- Alibaba Cloud Connection: Connection ID -->
          <simple-read-only-field v-if="cspConnection.csp_id"
            :label="$t('alibaba.connection-id')"
            :value="cspConnection.csp_id"
            data-testid="alibaba-connection-id" />
        </template>

        <template v-if="bEndConnectType === 'AWS'">
          <!-- Sub-title: AWS VIF Connection -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('aws.vif-connection') }}
          </h4>

          <!-- AWS VIF Cloud Connection: VIF ID -->
          <simple-read-only-field v-if="cspConnection.vif_id"
            :label="$t('aws.vif-id')"
            :value="cspConnection.vif_id"
            data-testid="aws-vif-id" />

          <!-- AWS VIF Cloud Connection: AWS Account ID -->
          <simple-read-only-field :label="$t('aws.account-id')"
            :value="cspConnection.ownerAccount"
            data-testid="aws-account-id" />

          <!-- AWS VIF Cloud Connection: AWS Label -->
          <simple-read-only-field :label="$t('aws.label')"
            :value="cspConnection.name"
            data-testid="aws-name" />

          <!-- AWS VIF Cloud Connection: Type -->
          <simple-read-only-field :label="$t('connections.type') | capitalizeString"
            :value="$t(`general.${cspConnection.type}`)"
            data-testid="aws-type" />

          <!-- AWS VIF Cloud Connection: Customer Address -->
          <simple-read-only-field :label="$t('aws.customer-address')"
            :value="cspConnection.customer_address"
            data-testid="aws-customer-address" />

          <!-- AWS VIF Cloud Connection: Customer ASN -->
          <simple-read-only-field :label="$t('connections.customer-asn')"
            :value="cspConnection.asn"
            data-testid="aws-customer-asn" />

          <!-- AWS VIF Cloud Connection: Peer ASN -->
          <simple-read-only-field v-if="cspConnection.type === 'public' && cspConnection.peerAsn"
            :label="$t('connections.bgp-peer-asn')"
            :label-tooltip="$t('connections.aws-default-asn')"
            :value="cspConnection.peerAsn"
            data-testid="public-peer-asn" />

          <!-- AWS VIF Cloud Connection: BGP Auth Key -->
          <simple-read-only-field :label="$t('aws.bgp-key')"
            :value="cspConnection.auth_key"
            data-testid="bgp-auth-key" />

          <!-- AWS VIF Cloud Connection: Amazon Address -->
          <simple-read-only-field :label="$t('aws.amazon-address')"
            :value="cspConnection.amazon_address"
            data-testid="amazon-address" />

          <!-- AWS VIF Cloud Connection: Prefixes -->
          <simple-read-only-field v-if="cspConnection.prefixes"
            :label="$t('salesforce.prefixes')"
            :value="cspConnection.prefixes"
            data-testid="salesforce-prefixes" />
        </template>

        <template v-if="bEndConnectType === 'AWSHC'">
          <!-- Sub-title: AWSHC Connection -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('aws.aws-hosted-connection') }}
          </h4>

          <!-- AWSHC Cloud Connection: AWS Connection ID -->
          <simple-read-only-field v-if="cspConnection.connectionId"
            :label="$t('aws.connection-id')"
            :value="cspConnection.connectionId"
            data-testid="awshc-connection-id" />

          <!-- AWSHC Cloud Connection: AWS Account ID -->
          <simple-read-only-field :label="$t('aws.account-id')"
            :value="cspConnection.ownerAccount"
            data-testid="awshc-account-id" />

          <!-- AWSHC Cloud Connection: AWS Label -->
          <simple-read-only-field :label="$t('aws.label')"
            :value="cspConnection.name"
            data-testid="awshc-connection-name" />
        </template>

        <template v-if="bEndConnectType === 'SFDC'">
          <!-- Sub-title: Salesforce Connection -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('salesforce.connection') }}
          </h4>

          <!-- Salesforce Cloud Connection: Customer ASN -->
          <simple-read-only-field :label="$t('connections.customer-asn')"
            :value="cspConnection.asn"
            data-testid="sfdc-customer-asn" />

          <!-- Salesforce Cloud Connection: Peer ASN -->
          <simple-read-only-field :label="$t('connections.bgp-peer-asn')"
            :value="cspConnection.peerAsn"
            data-testid="sfdc-bgp-peer-asn" />

          <!-- Salesforce Cloud Connection: Customer IP Address -->
          <simple-read-only-field :label="$t('connections.customer-ip')"
            :value="cspConnection.customerIpAddress"
            data-testid="sfdc-customer-ip" />

          <!-- Salesforce Cloud Connection: Peer IP Address -->
          <simple-read-only-field :label="$t('services.peer-ip-address')"
            :value="cspConnection.cspIpAddress"
            data-testid="sfdc-peer-ip-address" />

          <!-- Salesforce Cloud Connection: BGP Password -->
          <simple-read-only-field :label="$t('connections.bgp-password')"
            :value="cspConnection.password ? cspConnection.password : `(${$t('general.unspecified')})`"
            data-testid="sfdc-bgp-password" />

          <!-- Salesforce Cloud Connection: Permitted BGP Prefixes -->
          <simple-read-only-field :label="$t('salesforce.permitted-prefixes')"
            :value="bgpPrefixes"
            data-testid="sfdc-permitted-prefixes" />
        </template>

        <template v-if="bEndConnectType === 'GOOGLE'">
          <!-- Sub-title: Google Connection -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('google.connection') }}
          </h4>

          <!-- Google Cloud Connection: Pairing Key -->
          <simple-read-only-field :label="$t('connections.pairing-key')"
            :value="cspConnection.pairingKey"
            data-testid="google-pairing-key" />
        </template>

        <template v-if="bEndConnectType === 'OUTSCALE'">
          <!-- Sub-title: Outscale Connection -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('outscale.connection') }}
          </h4>

          <!-- Outscale Cloud Connection: Outscale Connection Name -->
          <simple-read-only-field v-if="cspConnection.name"
            :label="$t('outscale.connection-name')"
            :value="cspConnection.name"
            data-testid="outscale-connection-name" />

          <!-- Outscale Cloud Connection: Outscale Account ID -->
          <simple-read-only-field :label="$t('outscale.account-id')"
            :value="cspConnection.account_id"
            data-testid="outscale-account-id" />

          <!-- Outscale Cloud Connection: Customer ASN -->
          <simple-read-only-field :label="$t('connections.customer-asn')"
            :value="cspConnection.customer_asn"
            data-testid="outscale-customer-asn" />

          <!-- Outscale Cloud Connection: BGP Auth Key -->
          <simple-read-only-field v-if="cspConnection.auth_key"
            :label="$t('aws.bgp-key')"
            :value="cspConnection.auth_key"
            data-testid="outscale-bgp-key" />

          <!-- Outscale Cloud Connection: Customer IP Address -->
          <simple-read-only-field :label="$t('connections.customer-ip')"
            :value="cspConnection.customer_ip_address"
            data-testid="outscale-customer-ip" />

          <!-- Outscale Cloud Connection: Outscale IP address -->
          <simple-read-only-field :label="$t('connections.outscale-ip')"
            :value="cspConnection.provider_ip_address"
            data-testid="outscale-provider-ip" />
        </template>

        <template v-if="bEndConnectType === 'IBM'">
          <!-- Sub-title: IBM Connection -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('ibm.connection') }}
          </h4>

          <!-- IBM Cloud Connection: IBM Account ID -->
          <simple-read-only-field :label="$t('ibm.account-id')"
            :value="cspConnection.account_id"
            data-testid="ibm-account-id" />

          <!-- IBM Cloud Connection: Customer ASN -->
          <simple-read-only-field :label="$t('connections.customer-asn')"
            :value="cspConnection.customer_asn"
            data-testid="ibm-customer-asn" />

          <!-- IBM Cloud Connection: Customer IP Address -->
          <simple-read-only-field :label="$t('connections.customer-ip')"
            :value="cspConnection.customer_ip_address"
            data-testid="ibm-customer-ip" />

          <!-- IBM Cloud Connection: Provider IP Address -->
          <simple-read-only-field :label="$t('connections.provider-ip')"
            :value="cspConnection.provider_ip_address"
            data-testid="ibm-provider-ip" />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'

import captureSentryError from '@/utils/CaptureSentryError.js'

import SimpleReadOnlyField from '@/components/ui-components/SimpleReadOnlyField.vue'

export default {
  name: 'AdvancedInfo',

  components: {
    'simple-read-only-field': SimpleReadOnlyField,
  },

  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      createdByUser: null,
    }
  },

  computed: {
    ...mapGetters('MVE', ['getMVE']),
    ...mapGetters('Services', ['targetPorts']),
    ...mapGetters('Company', ['companyUid']),

    serviceIsIx() {
      return this.service.productType === this.G_PRODUCT_TYPE_IX
    },
    ipv4Address() {
      const addressObj = this.service.resources.ip_address?.find(address => address.resource_name === 'ipv4_address')

      return addressObj?.address || ''
    },
    ipv6Address() {
      const addressObj = this.service.resources.ip_address?.find(address => address.resource_name === 'ipv6_address')

      return addressObj?.address || ''
    },
    ipv4Connections() {
      return this.service.resources.bgp_connection?.filter(connection => connection.resource_name === 'rs1_ipv4_bgp_connection' || connection.resource_name === 'rs2_ipv4_bgp_connection')
    },
    ipv6Connections() {
      return this.service.resources.bgp_connection?.filter(connection => connection.resource_name === 'rs1_ipv6_bgp_connection' || connection.resource_name === 'rs2_ipv6_bgp_connection')
    },
    aEndPort() {
      if (this.serviceIsIx) return

      return this.targetPorts.find(targetPort => targetPort.productUid === this.service.aEnd.productUid) || {}
    },
    bEndPort() {
      if (this.serviceIsIx) return

      return this.targetPorts.find(targetPort => targetPort.productUid === this.service.bEnd.productUid) || {}
    },
    bEndConnectType() {
      if (this.serviceIsIx) return

      return this.bEndPort.connectType
    },
    aEndIsMve() {
      if (this.serviceIsIx) return false

      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MVE
    },
    bEndIsMve() {
      if (this.serviceIsIx) return false

      return this.bEndPort.productType === this.G_PRODUCT_TYPE_MVE
    },
    aEndOwned() {
      return this.service.productType === this.G_PRODUCT_TYPE_IX || this.service.aEnd?.ownerUid === this.companyUid
    },
    bEndOwned() {
      return this.service.productType === this.G_PRODUCT_TYPE_IX || this.service.bEnd?.ownerUid === this.companyUid
    },
    statusInfo() {
      const statusInfo = {
        status: '',
        message: '',
        bgpStatuses: [],
      }

      if (this.service.provisioningStatus !== this.G_PROVISIONING_LIVE) {
        statusInfo.message = `${this.$t('services.provisioning-status')}: ${this.service.provisioningStatus}`
      } else if (this.service.productType === this.G_PRODUCT_TYPE_VXC) {
        if (typeof this.service.up !== 'boolean') {
          statusInfo.status = 'partial-success'
          statusInfo.message = this.$t('connections.vxc-status-unknown')
        } else {
          statusInfo.status = this.service.up ? 'all-go' : 'all-fail'
          statusInfo.message = this.service.up ? this.$t('connections.vxc-is-up') : this.$t('connections.vxc-is-down')

          // There is only BGP information for MCRs, and it is part of the csp_connection object.
          // Add the BGP statuses as informational content.
          let csps = []

          // The API is currently returning csp_connection sometimes as an array and sometimes as an object,
          // which seems to depend on whether a single connection exists (object) or multiple (array).
          // Because of this, we need to check what we get and if not an array,
          // push the object into an empty array so that we can handle both cases similarly.
          if (this.service.resources?.csp_connection) {
            if (Array.isArray(this.service.resources.csp_connection)) {
              csps = this.service.resources.csp_connection
            } else {
              csps.push(this.service.resources.csp_connection)
            }
          }

          for (const cspInfo of csps) {
            if (cspInfo.connectType && cspInfo.connectType === 'VROUTER' && cspInfo.bgp_status) {
              const keys = Object.keys(cspInfo.bgp_status)

              for (const key of keys) {
                const thisStatus = {}

                thisStatus.message = `${this.$t('services.ip-address')}: ${key}`

                switch (cspInfo.bgp_status[key]) {
                  case 0:
                    thisStatus.status = 'all-fail'
                    thisStatus.statusMessage = this.$t('connections.bgp-down')
                    break
                  case 1:
                    thisStatus.status = 'all-go'
                    thisStatus.statusMessage = this.$t('connections.bgp-up')
                    break
                  default:
                    thisStatus.status = 'partial-success'
                    thisStatus.statusMessage = this.$t('connections.bgp-unknown')
                    break
                }

                statusInfo.bgpStatuses.push(thisStatus)
              }
            }
          }
        }
      } else if (this.serviceIsIx) {
        if (typeof this.service.up !== 'boolean') {
          statusInfo.status = 'partial-success'
          statusInfo.message = this.$t('connections.ix-status-unknown')
        } else {
          statusInfo.status = this.service.up ? 'all-go' : 'all-fail'
          statusInfo.message = this.service.up ? this.$t('connections.ix-is-up') : this.$t('connections.ix-is-down')

          // Add the BGP statuses as informational content.
          if (this.service.resources?.bgp_connection) {
            for (const bgp of this.service.resources.bgp_connection) {
              const thisStatus = {}

              thisStatus.message = `${this.$t('services.ip-address')}: ${bgp.isp_ip_address}`

              switch (bgp.up) {
                case 0:
                  thisStatus.status = 'all-fail'
                  thisStatus.statusMessage = this.$t('connections.bgp-down')
                  break
                case 1:
                  thisStatus.status = 'all-go'
                  thisStatus.statusMessage = this.$t('connections.bgp-up')
                  break
                default:
                  thisStatus.status = 'partial-success'
                  thisStatus.statusMessage = this.$t('connections.bgp-unknown')
                  break
              }

              statusInfo.bgpStatuses.push(thisStatus)
            }
          }
        }
      }

      return statusInfo
    },
    sameOwner() {
      if (!this.service || !this.service.aEnd || !this.service.bEnd) return false

      return this.service.aEnd.ownerUid === this.service.bEnd.ownerUid
    },
    /**
     * For Cloud Service Providers, the data will be returned in the service.resources.csp_connection key.
     * This is an array if connecting to an MCR (virtual), and just an object otherwise.
     */
    cspConnection() {
      if (!this.service?.resources) return null

      const { csp_connection } = this.service.resources

      if (csp_connection && Array.isArray(csp_connection) && csp_connection.length > 0) {
        const cspRet = csp_connection.find(connection => connection.connectType !== 'VROUTER')

        return cspRet || csp_connection[0]
      }

      return csp_connection || null
    },
    bgpPrefixes() {
      if (!this.cspConnection) return

      if (!this.cspConnection.prefixes || this.cspConnection.prefixes.length === 0) {
        return `(${this.$t('general.unspecified')})`
      }

      return this.cspConnection.prefixes.toString()
    },
  },

  mounted() {
    if (this.service.createdBy) {
      sdk.instance
        .employment()
        .get()
        .then(data => {
          this.createdByUser = data.find(user => user.personUid === this.service.createdBy)
        })
        .catch(e => {
          captureSentryError(e)
        })
    }
  },

  methods: {
    /**
     * Get the data to display for the vlan from either innerVlan or vlan as appropriated
     *
     * @param {string} end Either "aEnd" or "bEnd"
     * @param {boolean} isMve Whether that end is an MVE or not
     */
    vlanData(end, isMve) {
      const { innerVlan, vlan } = this.service[end]

      const relevantDisplayValue = isMve ? innerVlan : vlan

      return [null, -1].includes(relevantDisplayValue) ? this.$t('connections.untagged') : relevantDisplayValue
    },

    vNicValue(end) {
      const { productUid, vNicIndex } = this.service[end]
      const mve = this.getMVE(productUid)

      return `vNIC-${vNicIndex} ${mve?.vnics?.[vNicIndex].description || ''}`
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-table {
  margin-bottom: 2rem;

  &.total {
    border-top: 1px solid var(--card-border-color);
    border-bottom: 1px solid var(--card-border-color);
  }

  p {
    color: var(--color-text-regular);
  }
}
</style>
