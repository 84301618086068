<template>
  <div>
    <el-dialog :title="$t('connections.peer-config', {type: formattedType(peerForm.type)})"
      :visible.sync="showEditPeer">
      <el-form ref="peerForm"
        :model="peerForm"
        :rules="peerRules"
        label-width="150px">
        <el-form-item prop="peer_asn"
          :label="$t('connections.bgp-peer-asn')">
          <el-input v-model="peerForm.peer_asn"
            name="peerAsn"
            data-demo="65333" />
        </el-form-item>

        <el-form-item prop="primary_subnet"
          :label="$t('azure.primary-subnet')">
          <el-input v-model="peerForm.primary_subnet"
            name="primarySubnet"
            :placeholder="$t('azure.primary-subnet-placeholder')"
            data-demo="192.0.1.32/30" />
        </el-form-item>

        <el-form-item prop="secondary_subnet"
          :label="$t('azure.secondary-subnet')">
          <el-input v-model="peerForm.secondary_subnet"
            name="secondarySubnet"
            :placeholder="$t('azure.secondary-subnet-placeholder')"
            data-demo="192.0.1.32/30" />
        </el-form-item>

        <template v-if="peerForm.type === 'microsoft'">
          <el-form-item prop="prefixes"
            :label="$t('azure.public-prefixes')">
            <el-input v-model="peerForm.prefixes"
              name="publicPrefixes"
              :placeholder="$t('azure.public-prefixes-placeholder')"
              data-demo="191.0.2.0/24,191.0.2.0/30" />
          </el-form-item>
        </template>

        <el-form-item prop="shared_key"
          :label="$t('azure.shared-key')">
          <el-input v-model="peerForm.shared_key"
            name="sharedKey" />
        </el-form-item>

        <el-form-item prop="vlan"
          :label="$t('connections.vlan')">
          <el-input v-model="peerForm.vlan"
            name="vlan" />
        </el-form-item>
      </el-form>

      <template #footer>
        <el-button @click="showEditPeer = false">
          {{ $t('general.close') }}
        </el-button>
        <el-button @click="populatePeerForm">
          {{ $t('general.revert') }}
        </el-button>
        <el-button type="primary"
          name="updatePeer"
          @click="savePeerData">
          {{ $t('azure.update-peer') }}
        </el-button>
      </template>
    </el-dialog>

    <p v-if="!value.partnerConfig.managed">
      {{ $t('azure.editing-message') }}
    </p>
    <div v-else
      class="table-panel">
      <el-table :data="config.peers"
        :empty-text="$t('azure.no-peers')"
        data-name="peerData">
        <el-table-column :label="$t('azure.peer-type')"
          min-width="100">
          <template #default="{row}">
            <span class="capitalize-text"><strong>{{ row.type }}</strong></span>
          </template>
        </el-table-column>
        <el-table-column prop="peer_asn"
          :label="$t('connections.bgp-peer-asn')"
          min-width="100" />
        <el-table-column prop="primary_subnet"
          :label="$t('azure.primary-subnet')"
          min-width="150" />
        <el-table-column prop="secondary_subnet"
          :label="$t('azure.secondary-subnet')"
          min-width="150" />
        <el-table-column :label="$t('azure.public-prefixes')"
          min-width="140">
          <template #default="{row}">
            {{ row.type === 'microsoft' ? row.prefixes : $t('general.na') }}
          </template>
        </el-table-column>
        <el-table-column prop="shared_key"
          :label="$t('azure.shared-key')"
          min-width="100" />
        <el-table-column prop="vlan"
          :label="$t('connections.vlan')" />

        <el-table-column align="right"
          width="190">
          <template #default="{row, $index}">
            <template v-if="row.enabled">
              <el-button v-if="canDisable"
                type="warning"
                name="disable"
                @click="disableRow($index)">
                {{ $t('general.disable') }}
              </el-button>
              <el-button type="success"
                name="edit"
                @click="editRow($index)">
                {{ $t('general.edit') }}
              </el-button>
            </template>
            <el-button v-else
              type="success"
              name="enable"
              @click="editRow($index)">
              {{ $t('billing-markets.enable') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { capitalizeFirstOnly } from '@/helpers.js'
import { validateAsnDefault, IP_CIDR_REGEX } from '@/validators.js'

export default {
  name: 'AzureConfig',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      config: {
        peers: [],
      },
      showEditPeer: false,
      editingPeer: null,
      peerForm: {
        type: null,
        peer_asn: null,
        primary_subnet: null,
        secondary_subnet: null,
        prefixes: null,
        shared_key: null,
        vlan: null,
      },
      peerRules: {
        peer_asn: { required: true, validator: validateAsnDefault, trigger: 'blur' },
        primary_subnet: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('azure.primary-subnet') }), trigger: 'blur' },
          { pattern: `^${IP_CIDR_REGEX}$`, message: this.$t('validations.ip-cidr-format'), trigger: 'blur' },
        ],
        secondary_subnet: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('azure.secondary-subnet') }), trigger: 'blur' },
          { pattern: `^${IP_CIDR_REGEX}$`, message: this.$t('validations.ip-cidr-format'), trigger: 'blur' },
        ],
        prefixes: { pattern: `^(${IP_CIDR_REGEX})(,\\s*${IP_CIDR_REGEX})*$`, message: this.$t('validations.ip-list'), trigger: 'blur' },
        vlan: { required: true, message: this.$t('validations.required', { thing: this.$t('connections.vlan') }), trigger: blur },
      },
    }
  },
  computed: {
    // There must always be at least one peer type enabled.
    canDisable() {
      return this.config.peers.filter(peer => peer.enabled).length > 1
    },
  },
  created() {
    // Make sure we get a clean working object
    this.config.peers = []

    // Make sure we always have both options available in the same order as they were in the initial
    // interface. Set the values to be blank if it isn't enabled.
    const privatePeer = this.value.partnerConfig.peers?.find(peer => peer.type === 'private')
    if (privatePeer) {
      this.config.peers.push({ ...privatePeer, enabled: true })
    } else {
      this.config.peers.push(this.createPeer('private'))
    }

    const microsoftPeer = this.value.partnerConfig.peers?.find(peer => peer.type === 'microsoft')
    if (microsoftPeer) {
      this.config.peers.push({ ...microsoftPeer, enabled: true })
    } else {
      this.config.peers.push(this.createPeer('microsoft'))
    }
  },
  methods: {
    createPeer(type) {
      return {
        peer_asn: '',
        primary_subnet: '',
        secondary_subnet: '',
        shared_key: null,
        type: type,
        vlan: '',
        enabled: false,
      }
    },
    editRow(rowNum) {
      this.editingPeer = rowNum
      this.populatePeerForm()
      this.showEditPeer = true
    },
    disableRow(rowNum) {
      this.$confirm(this.$t('azure.disable-peering-type'), this.$t('azure.disable-peer-type'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
      })
        .then(() => {
          const replacement = this.createPeer(this.config.peers[rowNum].type)
          this.$set(this.config.peers, rowNum, replacement)
          this.emitUpdatedConfig()
        })
        .catch(() => {
          // empty function is intentional
        })
    },
    populatePeerForm() {
      Object.assign(this.peerForm, this.config.peers[this.editingPeer])
      if (this.$refs.peerForm) {
        this.$refs.peerForm.clearValidate()
      }
    },
    formattedType(type) {
      return capitalizeFirstOnly(type)
    },
    emitUpdatedConfig() {
      const enabledPeers = this.config.peers
        .filter(peer => peer.enabled)
        .map(peer => {
          const newPeer = { ...peer }
          delete newPeer.enabled
          return newPeer
        })

      this.$emit('input', {
        productUid: this.value.productUid,
        partnerConfig: {
          peers: enabledPeers,
          connectType: 'AZURE',
          complete: true,
          managed: true,
        },
      })
    },
    savePeerData() {
      this.$refs.peerForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)

          return false
        }

        Object.assign(this.config.peers[this.editingPeer], this.peerForm)
        this.config.peers[this.editingPeer].enabled = true

        this.emitUpdatedConfig()

        this.showEditPeer = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-panel {
  min-width: 700px;
  margin-bottom: 2rem;
}
</style>
