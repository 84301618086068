<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    data-testid="ibm-modal"
    :title="$t('ibm.modal-title')">
    <div>
      <p>{{ $t(`ibm.edit-modal-updated-api`) }}</p>
      <p>{{ $t(`ibm.edit-modal-recommendation`) }}</p>
      <i18n path="ibm.edit-modal-docs"
        tag="p">
        <template #link>
          <a href="https://docs.megaport.com/cloud/megaport/ibm/direct-link-2.0/"
            target="_blank"
            rel="noopener">{{ $t('general.documentation') }}</a>
        </template>
      </i18n>
    </div>
    <template #footer>
      <el-button data-testid="ibm-close-btn"
        @click="setVisible(false)">
        {{ $t('general.close') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

/**
 * This modal shows while in the edit connection page when 
 * editing an old IBM direct link 1.0 connection alerting users
 * that the API integrations have been updated, and ordering a
 * new IBM direct link 2.0 connection is recommended.
 */
export default {
  name: 'EditConnectionIbmModal',

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:visible'],

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-width: 700px;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
