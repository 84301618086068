<template>
  <div v-loading="isLoading"
    class="flex-column full-width full-height flex-align-center flex-justify-center">
    <div v-if="aEndOwned"
      class="flex-row-centered mb-1">
      <span class="mr-1">{{ $t('graph.billing-month') }}</span>
      <el-date-picker v-model="startMonth"
        type="month"
        format="MMMM yyyy"
        :picker-options="pickerOptions"
        :clearable="false"
        :editable="false"
        class="non-editable-date-picker"
        @change="dateChanged" />
    </div>
    <div v-if="!aEndOwned">
      {{ $t('graph.a-end-not-owned') }}
    </div>
    <div v-else-if="isError"
      class="info-bar">
      {{ $t('general.load-failed') }}
    </div>
    <div v-else-if="!billingData || !billingData.boxes"
      class="info-bar">
      {{ $t('graph.no-billing-data') }}
    </div>
    <div v-else
      class="flex-column full-width full-height">
      <div class="text-align-center">
        <h4>{{ billingData.totalDisplay }}</h4>
        <p v-if="isCurrentMonth">
          {{ $t('graph.estimated') }}
        </p>
        <p v-else>
          {{ $t('graph.calculated') }}
        </p>
      </div>
      <div v-if="tooManyChanges"
        class="info-bar">
        {{ $t('graph.too-many-changes') }}
      </div>
      <template v-else>
        <div class="text-align-center">
          <h4 class="graphBillingColor">
            {{ $t('graph.configured-speed') }}
          </h4>
        </div>
        <vue-plotly v-if="isReady"
          :data="processedGraphData"
          :layout="graphLayout"
          :options="graphOptions"
          :auto-resize="true"
          class="plotly-graph" />
        <hr>

        <div class="text-align-center my-2 mx-0">
          <h3>{{ $t('graph.billing-details') }}</h3>
          <p v-if="isCurrentMonth">
            {{ $t('graph.estimated-next') }}
          </p>
        </div>

        <el-table v-if="isReady"
          :data="tabularData"
          show-summary
          :summary-method="getTotals"
          :default-sort="{prop: 'start', order: 'ascending'}"
          class="data-table">
          <el-table-column prop="speed"
            sortable
            :label="$t('graph.speed')" />
          <el-table-column prop="start"
            sortable
            :label="$t('general.start')" />
          <el-table-column prop="end"
            sortable
            :label="$t('general.end')" />
          <el-table-column :label="$t('general.duration')">
            <template #default="{row}">
              {{ formattedDuration(row.duration) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('general.price')"
            sortable
            align="right"
            header-align="right"
            prop="price">
            <template #default="{row}">
              {{ row.price | formatInCurrency(row.currency) }}
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="text-align-center">
        <el-button @click="saveCsv">
          {{ $t('graph.download') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import sdk from '@megaport/api-sdk'
import { moneyFilter, deepClone, getLocaleLanguage } from '@/helpers.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { readCssVar } from '@/utils/CssVars.js'
import humanizeDuration from 'humanize-duration'

export default {
  name: 'ServiceBillingGraph',

  components: {
    'vue-plotly': () => import(/* webpackChunkName: "VuePlotLy" */ '@statnett/vue-plotly'),
  },

  filters: {
    formatInCurrency: moneyFilter,
  },

  props: {
    serviceId: {
      type: String,
      required: true,
    },
    aEndOwned: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
      startMonth: DateTime.now()
        .setZone('UTC+10')
        .startOf('month')
        .toISO(),
      billingData: null,
      tabularData: [],

      isReady: false,
      isLoading: true,
      isError: false,
      processedGraphData: [],

      graphLayout: {
        autosize: true,
        xaxis: {
          type: 'date',
          tickmode: 'linear',
          tick0: 0.5,
          tickformat: '%d',
          nticks: 31,
          linecolor: '#dcdfe6',
          linewidth: 1,
          mirror: true,
          title: this.$t('general.date'),
          hoverformat: '%a %d %b %Y %H:%M GMT',
        },
        yaxis: {
          typeMeasurement: 'Mbps',
          exponentformat: 'none',
          linecolor: '#dcdfe6',
          linewidth: 1,
          mirror: true,
          hoverformat: 'f Mbps',
          title: this.$t('graph.configured-speed'),
          rangemode: 'tozero',
          ticksuffix: this.$t('general.mbps'),
        },
        margin: {
          l: 100,
          r: 50,
          t: 20,
          b: 60,
        },
        showlegend: false,
        annotations: [],
      },
      graphOptions: {
        responsive: true,
        displaylogo: false,
        displayModeBar: false,
      },

      maxGraphChanges: 31, // If more changes than this in a month, don't display the graph, table.
      destroyed: false, // Protect against processing the promise response if component is going away
    }
  },
  computed: {
    ...mapGetters('Services', ['findService']),
    isCurrentMonth() {
      const startMonthMillis = Date.parse(this.startMonth)
      const startOfMonth = DateTime.fromMillis(startMonthMillis)
        .setZone('UTC+10')
        .startOf('month')

      const endOfMonth = DateTime.fromMillis(startMonthMillis)
        .setZone('UTC+10')
        .endOf('month')

      const now = DateTime.now().setZone('UTC+10')

      return now < endOfMonth && now > startOfMonth
    },
    concatenatedBoxes() {
      // The data often has multiple boxes with the same speed, so to make it easier to graph and label them
      // we want to concatenate the ones with the same speed. Note that the boxes are already sorted by date
      const boxes = []
      let boxHeight = null
      let lastAddedBox = null
      for (const box of this.billingData.boxes) {
        if (box.tl.y !== boxHeight) {
          const boxCopy = deepClone(box)
          boxes.push(boxCopy)
          boxHeight = boxCopy.tl.y
          lastAddedBox = boxCopy
        } else {
          lastAddedBox.price += box.price
          lastAddedBox.tr.x = box.tr.x
          lastAddedBox.br.x = box.br.x
        }
      }

      return boxes
    },
    tooManyChanges() {
      return this.concatenatedBoxes.length > this.maxGraphChanges
    },
    serviceCreationDate() {
      const service = this.findService(this.serviceId)
      return service ? new Date(service.createDate) : null
    },
  },

  mounted() {
    this.fetchData()
  },

  beforeDestroy() {
    this.destroyed = true
  },

  methods: {
    disabledDate(time) {
      if (this.serviceCreationDate) {
        if (time.getFullYear() < this.serviceCreationDate.getFullYear()) {
          return true
        }
        if (time.getFullYear() === this.serviceCreationDate.getFullYear() && time.getMonth() < this.serviceCreationDate.getMonth()) {
          return true
        }
      }
      return time.getTime() > Date.now()
    },
    dateChanged() {
      this.fetchData()
    },
    formattedDuration(duration) {
      return humanizeDuration(duration, {
        language: getLocaleLanguage(this.$i18n.locale),
        largest: 1,
        units: ['y', 'mo', 'd', 'h', 'm'],
        round: true,
      })
    },
    getTotals({ columns, data }) {
      const summaries = []
      const total = data.reduce((sum, item) => sum + item.price, 0)

      for (const column of columns) {
        if (column.property !== 'price') {
          summaries.push('')
        } else if (data.length) {
          summaries.push(`${this.$t('billing-markets.total')}: ${moneyFilter(total, data[0].currency)}`)
        } else {
          summaries.push('')
        }
      }
      return summaries
    },
    fetchData() {
      this.isReady = false
      this.isLoading = true
      this.isError = false

      if (!this.serviceId || !this.aEndOwned) {
        this.isReady = true
        this.isLoading = false
        return
      }

      const startDate = DateTime.fromMillis(Date.parse(this.startMonth))
        .setZone('UTC+10')

      sdk.instance
        .product(this.serviceId)
        .history(startDate.year, startDate.month)
        .then(data => {
          if (data && data.boxes && data.boxes.length) {
            data.totalDisplay = moneyFilter(data.total, data.currency)
            // The boxes come to us sorted by size and we need to graph sorted by date
            data.boxes.sort((a, b) => a.bl.x - b.bl.x)
            this.billingData = data
            if (!this.destroyed) {
              this.recalculateProcessedGraphData()
            }
            this.isReady = true
            this.isLoading = false
          } else {
            this.isLoading = false
            this.billingData = null
            this.tabularData = []
          }
        })
        .catch(e => {
          if ([400, 403].includes(e.status) && e.data?.message) {
            this.$notify.error({
              message: e.data.message,
            })
          } else {
            captureSentryError(e)
          }

          this.billingData = null
          this.tabularData = []
          this.isLoading = false
          this.isError = true
        })
    },
    rebuildTabularData() {
      // Build up the tabular data for the chart
      this.tabularData = []
      for (const data of this.concatenatedBoxes) {
        const newItem = {
          speed: data.tl.y,
          price: data.price,
          start: DateTime.fromMillis(data.tl.x)
            .setZone('UTC+10')
            .toFormat('y-LL-dd HH:mm:ss'),
          end: DateTime.fromMillis(data.tr.x)
            .setZone('UTC+10')
            .toFormat('y-LL-dd HH:mm:ss'),
          duration: data.tr.x - data.tl.x,
          currency: this.billingData.currency,
        }
        this.tabularData.push(newItem)
      }
    },
    buildMainGraphAxes() {
      const xAxis = []
      const yAxis = []

      const numConcatBoxes = this.concatenatedBoxes.length
      for (let i = 0; i < numConcatBoxes; i++) {
        const data = this.concatenatedBoxes[i]
        // Add the point for the current position
        xAxis.push(DateTime.fromMillis(data.bl.x)
          .setZone('UTC+10')
          .set({ milliseconds: 0 })
          .toISO({ suppressMilliseconds: true }))
        yAxis.push(data.tl.y)
        // If this is the last box, then draw the line to the end of the month or now (whichever is earlier)
        // and add a label to the last segment
        if (i === numConcatBoxes - 1) {
          const endOfMonth = DateTime.fromMillis(data.bl.x)
            .setZone('UTC+10')
            .endOf('month')
          const now = DateTime.now().setZone('UTC+10')
          const endDate = now < endOfMonth ? now : endOfMonth

          xAxis.push(endDate.set({ milliseconds: 0 }).toISO({ suppressMilliseconds: true }))
          yAxis.push(data.tl.y)
        }
      }
      return { xAxis, yAxis }
    },
    generateMainGraph() {
      const { xAxis, yAxis } = this.buildMainGraphAxes()
      const startMonthMillis = Date.parse(this.startMonth)
      this.graphLayout.xaxis.title = DateTime.fromMillis(startMonthMillis)
        .setZone('UTC+10')
        .toFormat('LLLL	y')

      this.graphLayout.xaxis.range = [
        DateTime.fromMillis(startMonthMillis)
          .setZone('UTC+10')
          .toMillis(),
        DateTime.fromMillis(startMonthMillis)
          .setZone('UTC+10')
          .endOf('month')
          .toMillis(),
      ]
      const graph = {
        x: xAxis,
        y: yAxis,
        type: 'scatter',
        mode: 'lines',
        name: this.$t('general.mbps'),
        rangemode: 'tozero',
        line: {
          shape: 'hv',
          color: readCssVar('--mp-graph-billing-color'),
        },
        fill: 'tozeroy',
        hoverinfo: 'y',
        hoverlabel: {
          bgcolor: '#ffffff',
        },
      }
      return graph
    },
    generateGraphToEndOfMonth() {
      const now = DateTime.now().setZone('UTC+10')
      const lastBox = this.concatenatedBoxes[this.concatenatedBoxes.length - 1]
      const endOfMonth = DateTime.fromMillis(lastBox.bl.x)
        .setZone('UTC+10')
        .endOf('month')

      const endGraph = {
        x: [now.toISO(), endOfMonth.toISO()],
        y: [lastBox.tl.y, lastBox.tl.y],
        type: 'scatter',
        mode: 'lines',
        rangemode: 'tozero',
        hoverinfo: 'none',
        line: {
          shape: 'linear',
          color: readCssVar('--mp-graph-billing-color'),
          dash: 'dash',
        },
      }
      return endGraph
    },
    recalculateProcessedGraphData() {
      if (!this.billingData || !this.billingData.boxes) {
        this.processedGraphData = []
        this.tabularData = []
        return false
      }
      this.rebuildTabularData()
      const graph = this.generateMainGraph()

      const graphs = [graph]
      // If we're looking at the current month, draw a dashed line after that until the end of the month.
      if (this.isCurrentMonth && this.concatenatedBoxes.length) {
        const endGraph = this.generateGraphToEndOfMonth()
        graphs.push(endGraph)
      }
      this.processedGraphData = graphs
    },
    saveCsv() {
      const data = []
      data.push(['"speed"', '"start"', '"start date"', '"start time"', '"end"', '"end date"', '"end time"', '"cost"'])
      for (const box of this.billingData.boxes) {
        const startMoment = DateTime.fromMillis(box.bl.x).setZone('UTC+10')
        const endMoment = DateTime.fromMillis(box.br.x).setZone('UTC+10')
        data.push([
          box.tl.y,
          `"${startMoment.set({ milliseconds: 0 }).toISO({ suppressMilliseconds: true })}"`,
          `"${startMoment.toFormat('y-LL-dd')}"`,
          `"${startMoment.toFormat('HH:mm:ss')}"`,
          `"${endMoment.set({ milliseconds: 0 }).toISO({ suppressMilliseconds: true })}"`,
          `"${endMoment.toFormat('y-LL-dd')}"`,
          `"${endMoment.toFormat('HH:mm:ss')}"`,
          box.price,
        ])
      }
      const content = data.map(e => e.join(',')).join('\n')

      const blob = new Blob([content], { type: 'text/csv', encoding: 'utf-8' })
      const urlObj = URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.setAttribute('style', 'display:none')
      anchor.setAttribute('href', urlObj)
      anchor.setAttribute('download', 'billing_data.csv')
      document.body.appendChild(anchor)

      anchor.click()

      URL.revokeObjectURL(urlObj)
    },
  },
}
</script>

<style lang="scss" scoped>
.info-bar {
  background-color: var(--color-white);
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 2rem;
  line-height: normal;
}
.plotly-graph {
  height: calc(100% - 20px);
}
.data-table {
  width: calc(100% - 2px); // Avoids jitter on Chrome Windows
  margin-bottom: 2rem;
}
</style>
