<template>
  <div>
    <!-- Step Navigation Row -->
    <div class="header"
      :class="{'sticky-top':!layout.integratedHeader}">
      <!-- Step Navigation Row Title -->
      <h3 class="text-align-center">
        {{ $t('connections.connection-details') }}
      </h3>

      <!-- Step Navigation Row Step Buttons -->
      <div class="steps wl-steps"
        role="presentation">
        <el-steps :active="tabIndex"
          :align-center="true"
          data-testid="edit-connection-stepper"
          class="connection-details m-auto mt-1-3 max-width-720px">
          <el-step v-for="(step, index) in allTabs"
            :key="index"
            :title="step.title"
            class="cursor-pointer"
            :icon="step.icon"
            :status="index === tabIndex ? 'process' : 'finish'"
            :data-step-name="step.title"
            :data-testid="`edit-connection-step-${step.title}`"
            @click.native="gotoTab(index)" />
        </el-steps>
      </div>
    </div>

    <!-- Main Content -->
    <div class="p-20px pb-50px">
      <!-- Connection Detail Cards -->
      <div class="flex-column flex-align-center">
        <connection-details-cards :left-active="currentTab.id === 'configure-aend'"
          :left-detail-lines="aEndDetailLines"
          :left-diversity-zone="diversityZones.aEnd"
          :left-left-icon-id="iconType.aEnd"
          :right-active="currentTab.id === 'configure-bend'"
          :right-detail-lines="bEndDetailLines"
          :right-diversity-zone="diversityZones.bEnd"
          :right-left-icon-id="iconType.bEnd" />
      </div>

      <!-- Pricebox -->
      <div class="price-box">
        <span v-if="priceStore && showPricing">
          <strong>{{ $t('pricebook.monthly-rate') }}: </strong>
          <span data-name="monthlyRate">{{ priceStore.monthlyRate | formatInCurrency(priceStore.currency) }}</span>
          <span> ({{ $t('pricebook.excludes-tax') }})</span>
        </span>
      </div>

      <!-- Step-Driven Content -->
      <megaport-spinner v-if="isLoadingService"
        class="mt-6" />
      <div v-else
        class="content-panel"
        :class="currentTab.id === 'logs' || currentTab.id === 'usage' || currentTab.id === 'billing' ? 'full-size' : ''">
        <!-- For all the tabs with editable content -->

        <div v-loading="savingConnection"
          :element-loading-text="loadingText">
          <el-alert v-if="showButtons && nonEditReason"
            type="warning"
            :closable="false">
            <p v-html="nonEditReason" /> <!-- eslint-disable-line vue/no-v-html -->

            <template v-if="approvalPending.type === 'NEW' && approvalPending.internal && approvalPending.permitted">
              <p>{{ $t('connections.approve-prompt') }}</p>
              <div class="text-align-center">
                <el-button type="danger"
                  data-testid="reject-button"
                  name="denySpeedChange"
                  @click="requestApproved = false">
                  {{ $t('connections.deny-connection') }}
                </el-button>
                <el-button type="success"
                  data-testid="approve-button"
                  name="approveSpeedChange"
                  @click="requestApproved = true">
                  {{ $t('connections.approve-connection') }}
                </el-button>
              </div>
            </template>
          </el-alert>

          <el-form ref="connectionForm"
            :model="connectionForm"
            :rules="connectionRules"
            label-width="200px"
            :disabled="!editable"
            :class="editable ? '' : 'show-non-editable'"
            @submit.native.prevent>
            <!-- Tab: Connection -->
            <div v-if="currentTab.id === 'configure-connection'">
              <!-- Card Title: Connection Configuration -->
              <h3 class="text-align-center mb-3"
                data-name="config-header">
                {{ $t('connections.configuration') }}
              </h3>

              <connection-config ref="connectionConfig"
                :connection-form.sync="connectionForm"
                :service="serviceObj"
                :a-end-port="aEndPort"
                :b-end-port="bEndPort"
                :b-end-config="connectionForm.bEndConfig"
                :editable="editable"
                :approval-pending="approvalPending"
                :min-rate-limit="minRateLimit"
                :max-rate-limit="maxRateLimit"
                :deals="deals"
                @requestapproved="requestApproved = true"
                @requestdenied="requestApproved = false"
                @updateAzurePeering="azurePeering" />
            </div>

            <!-- Tab: A-End (MCR) -->
            <div v-if="currentTab.id === 'configure-aend'
              && connectionForm.aEndConfig.partnerConfig
              && aEndOwned">
              <!-- Do NOT make the same mistake as me and remove the key on this! Thanks to some
                primo spaghetti code the key is required to stop aEnd state spilling into the bEnd -->
              <mcr-config key="aEnd"
                end="A"
                :config="connectionForm.aEndConfig"
                :mcr-port="aEndPort"
                :service-obj="serviceObj"
                :readonly="!editable"
                @edit="switchConnectionFormEditing"
                @update="updateMcrConfig($event, 'aEndConfig')"
                @saveConnection="saveConnection" />
            </div>

            <!-- Tab: B-End (MCR) -->
            <div v-if="currentTab.id === 'configure-bend'
              && connectionForm.bEndConfig.partnerConfig
              && bEndOwned">
              <!-- Do NOT make the same mistake as me and remove the key on this! Thanks to some
                primo spaghetti code the key is required to stop aEnd state spilling into the bEnd -->
              <mcr-config key="bEnd"
                end="B"
                :config="connectionForm.bEndConfig"
                :mcr-port="bEndPort"
                :service-obj="serviceObj"
                :readonly="!editable"
                @edit="switchConnectionFormEditing"
                @update="updateMcrConfig($event, 'bEndConfig')"
                @saveConnection="saveConnection" />
            </div>

            <div v-if="currentTab.id === 'configure-aws' && connectionForm.bEndConfig.partnerConfig">
              <h3 class="text-align-center my-1"
                data-name="config-header">
                {{ $t('general.configure-thing', { thing: 'AWS' }) }}
              </h3>

              <aws-config v-model="connectionForm.bEndConfig"
                :is-mcr="aEndIsMcr"
                :is-editing="true"
                :readonly="!editable"
                :show-warning="!bEndEdited"
                :connect-type="bEndPort.connectType"
                @edited="bEndEdited = true" />
            </div>

            <div v-if="currentTab.id === 'configure-sfdc' && connectionForm.bEndConfig.partnerConfig">
              <h3 class="text-align-center my-1"
                data-name="config-header">
                {{ $t('general.configure-thing', { thing: 'Salesforce' }) }}
              </h3>
              <sfdc-config v-model="connectionForm.bEndConfig"
                :is-mcr="aEndIsMcr"
                :is-editing="true" />
            </div>

            <div v-if="currentTab.id === 'configure-azure' && connectionForm.bEndConfig.partnerConfig">
              <h3 class="text-align-center mb-3"
                data-name="config-header">
                {{ $t('general.configure-thing', { thing: 'Azure ExpressRoute' }) }}
              </h3>

              <azure-config v-model="connectionForm.bEndConfig" />
            </div>

            <div v-if="editable && showButtons"
              class="text-align-right">
              <!-- Modification Delay Disclaimer -->
              <el-alert type="info"
                :title="$t('general.modification-delay')"
                :closable="false"
                center
                show-icon
                class="my-1 py-1" />
              <!-- Revert Button -->
              <el-button data-testid="revert-changes-button"
                :loading="loadingPrice"
                @click="initForm">
                {{ $t('general.revert') }}
              </el-button>
              <!-- Save Button -->
              <el-button :loading="loadingPrice"
                type="primary"
                data-name="saveConnection"
                data-testid="save-connection-button"
                @click="saveConnection">
                {{ $t('general.save') }}
              </el-button>
              <hr class="my-1">
            </div>
          </el-form>
        </div>

        <!-- The following are display only -->
        <!-- Tab: Details -->
        <div v-if="currentTab.id === 'advanced'">
          <!-- Card Title: Configuration Details -->
          <h3 class="text-align-center mb-3">
            {{ $t('services.configuration-details') }}
          </h3>

          <advanced-info :service="rawService" />
        </div>

        <!-- Tab: Logs -->
        <div v-if="currentTab.id === 'logs'">
          <h3 class="text-align-center mb-3">
            {{ $t('services.service-logs') }}
          </h3>
          <service-logs :service-id="rawService.productUid" />
        </div>

        <!-- Tab: Usage -->
        <div v-if="currentTab.id === 'usage'">
          <service-graph :service="rawService"
            :max="aEndPort.speed"
            :flip="!isOwner" />
        </div>

        <!-- Tab: Billing -->
        <div v-if="currentTab.id === 'billing'">
          <h3 class="text-align-center mb-3">
            {{ $t('general.billing') }}
          </h3>

          <billing-graph :service-id="rawService.productUid || ''"
            :a-end-owned="aEndOwned" />
        </div>

        <!-- Card Footer Buttons -->
        <div class="text-align-right">
          <!-- Close Button -->
          <el-button data-name="closeEditConnection"
            data-testid="edit-connection-close-button"
            @click="handleCloseBtn">
            {{ $t('general.close') }}
          </el-button>
          <!-- Back Button -->
          <el-button v-if="tabIndex > 0"
            type="primary"
            plain
            class="step-button"
            data-testid="edit-connection-back-button"
            @click="goToLastTab">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('general.back') }}
          </el-button>
          <!-- Next Button -->
          <el-button v-if="!finalTab"
            type="primary"
            plain
            class="step-button"
            data-testid="edit-connection-next-button"
            @click="goToNextTab">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-circle-right"
              aria-hidden="true" />
          </el-button>
        </div>
      </div>
    </div>

    <price-change :price="priceChange"
      :current-monthly-price="currentMonthlyPrice"
      @close="cancelPriceChange()"
      @accept="acceptPriceChange()" />

    <!-- Modal that shows when users are editing an old IBM direct link 1.0 connection -->
    <edit-ibm-modal v-if="showEditIbmModal"
      :visible.sync="showEditIbmModal" />
  </div>
</template>

<script>
// External tools
import Vue from 'vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { pick, get, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import stableStringify from 'fast-json-stable-stringify'
import sdk from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import { validateAsnDefault, validateAwsAsn, validateBGPPassword, validateIxAsn, validateIxPeerMacro, validateReverseDns, IP_CIDR_REGEX } from '@/validators.js'
import { deepClone, maxVXCSpeed, minVXCSpeed, maxIXSpeed, moneyFilter, isCloudConnection } from '@/helpers.js'
import { CLOUD_ITEMS } from '@/Globals.js'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { scopedPriceBook } from '@/utils/priceBook.js'
// Components
import PriceChangeComponent from '@/components/PriceChange.vue'
import ServiceLogsNewComponent from '@/components/ServiceLogs.vue'
import ServiceGraphComponent from '@/components/graphs/ServiceGraph.vue'
import ServiceBillingGraphComponent from '@/components/graphs/ServiceBillingGraph.vue'
import AdvancedInfoComponent from '@/components/edit-connection-extras/AdvancedInfo.vue'
import McrConfigComponent from '@/components/connection-extras/McrConfig.vue'
import AwsConfigComponent from '@/components/connection-extras/AwsConfig.vue'
import AzureConfigComponent from '@/components/connection-extras/AzureConfig.vue'
import SfdcConfigComponent from '@/components/connection-extras/SfdcConfig.vue'
import EditConfigComponent from '@/components/edit-connection-extras/Config.vue'
import ConnectionDetailsCards from '@/components/ui-components/ConnectionDetailsCards.vue'
import EditConnectionIbmModal from '@/components/EditConnectionIbmModal.vue'
import { macAddressValidationRule } from '@/components/ui-components/MacAddress.vue'
// route history
import { history } from '@/routeGuards'
import MegaportSpinner from '@/components/ui-components/MegaportSpinner.vue'

const DEFAULT_IX_MIN = 1

export default {
  name: 'EditConnection',

  components: {
    'price-change': PriceChangeComponent,
    'service-logs': ServiceLogsNewComponent,
    'service-graph': ServiceGraphComponent,
    'billing-graph': ServiceBillingGraphComponent,
    'advanced-info': AdvancedInfoComponent,
    'mcr-config': McrConfigComponent,
    'aws-config': AwsConfigComponent,
    'sfdc-config': SfdcConfigComponent,
    'azure-config': AzureConfigComponent,
    'connection-config': EditConfigComponent,
    'connection-details-cards': ConnectionDetailsCards,
    'edit-ibm-modal': EditConnectionIbmModal,
    'megaport-spinner': MegaportSpinner,
  },

  filters: {
    formatInCurrency: moneyFilter,
  },

  inject: ['layout', 'disabledFeatures', 'isFeatureEnabled'],

  // This can happen for example when you have two approvals waiting and you directly switch from one to the other.
  beforeRouteUpdate() {
    this.setup()
  },

  data() {
    return {
      isLoadingService: true,
      dataRefreshInterval: 60 * 1000, // How often we should refresh the data (ms)
      tabIndex: 0,
      formInitialised: false,
      approvalPending: {
        internal: false,
        type: null, // Set to null if no approval required
        newSpeed: null,
        oldSpeed: null,
        permitted: false,
        approveUid: '',
        message: '',
      },
      validatingForSave: false,
      savingConnection: false,
      saveConnectionFailed: false,
      connectionFormEditing: false,
      connectionForm: {
        productName: null,
        secondaryName: null,
        costCentre: null,
        dealUid: null,
        rateLimit: null,
        asn: null,
        password: null,
        aEnd: {
          vlan: null,
          innerVlan: null,
          vNicIndex: null,
        },
        bEnd: {
          vlan: null,
          innerVlan: null,
          vNicIndex: null,
        },
        vlan: null,
        macAddress: null,
        publicGraph: false,
        ixPeerMacro: null,
        reverseDns: null,
        bEndConfig: {
          partnerConfig: null,
        },
        shutdown: false,
      },
      connectionRules: {
        productName: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('connections.connection-name') }), trigger: 'blur' },
          { min: 0, max: 170, message: this.$tc('validations.max-length', 170, { max: 170 }) },
        ],
        asn: { required: true, validator: validateIxAsn, trigger: 'blur' },
        costCentre: { min: 0, max: 255, message: this.$tc('validations.max-length', 255, { max: 255 }) },
        password: { validator: validateBGPPassword, trigger: 'blur' },
        secondaryName: { required: true, message: this.$t('validations.required', { thing: this.$t('connections.connection-name') }), trigger: 'blur' },
        rateLimit: { required: true, validator: this.validateRateLimit, trigger: 'blur' },
        publicGraph: { required: true },
        ixPeerMacro: { required: false, validator: validateIxPeerMacro, trigger: 'blur' },
        reverseDns: { required: false, validator: validateReverseDns, trigger: 'blur' },
        // AWS
        'bEndConfig.partnerConfig.type': { required: true, message: this.$t('validations.please-select', { thing: this.$t('connections.type') }), trigger: 'change' },
        'bEndConfig.partnerConfig.name': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.aws-connection-name') }), trigger: 'blur' },
        'bEndConfig.partnerConfig.ownerAccount': [
          { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.aws-owner-acct-id') }), trigger: 'change' },
          {
            len: 12,
            message: this.$t('validations.aws-account'),
            trigger: 'change',
            transform(value) {
              return value.replace(/[^0-9]+/g, '')
            },
          },
        ],
        'bEndConfig.partnerConfig.asn': { required: false, validator: this.validateBEndAsn, trigger: 'blur' },
        'bEndConfig.partnerConfig.authKey': { pattern: '^[^\\s]{6,24}$', message: this.$t('validations.min-max-length', { min: 6, max: 24 }), trigger: 'blur' },
        // See watch on public for changing whether this is required
        'bEndConfig.partnerConfig.customerIpAddress': [
          { required: true, message: this.$t('validations.required', { thing: this.$t('connections.customer-ip') }), trigger: 'blur' },
          { pattern: `^${IP_CIDR_REGEX}$`, message: this.$t('validations.ip-cidr-format'), trigger: 'blur' },
        ],
        'bEndConfig.partnerConfig.amazonIpAddress': [
          { required: true, message: this.$t('validations.required', { thing: this.$t('connections.amazon-ip') }), trigger: 'blur' },
          { pattern: `^${IP_CIDR_REGEX}$`, message: this.$t('validations.ip-cidr-format'), trigger: 'blur' },
        ],
        'bEndConfig.partnerConfig.prefixes': {
          pattern: `^(${IP_CIDR_REGEX})(,\\s*${IP_CIDR_REGEX})*$`,
          message: this.$t('validations.ip-list'),
          trigger: 'blur',
        },
        'aEnd.vlan': { validator: this.validateVlan, trigger: 'blur' },
        'bEnd.vlan': { validator: this.validateVlan, trigger: 'blur' },
        'aEnd.innerVlan': { validator: this.validateVlan, trigger: 'blur' },
        'bEnd.innerVlan': { validator: this.validateVlan, trigger: 'blur' },
        'aEnd.vNicIndex': { required: true, message: this.$t('validations.vnic'), trigger: 'none' },
        'bEnd.vNicIndex': { required: true, message: this.$t('validations.vnic'), trigger: 'none' },
        ...macAddressValidationRule('macAddress', true, 'blur'),
      },
      /** START **/
      // Part of a workaround to display the current recurring monthly rate in PriceChange (see ENG-21893)
      // API: We need GET /v2/product/:productUid/rating/:year/:month?newSpeed=<speed> to include the current recurring monthly rate (see ENG-21967)
      // TODO: Remove this as part of the necessary refactor in ENG-21968
      currentMonthlyPrice: null,
      currentMonthlyPriceCaptured: false,
      /** END **/
      priceStore: false,
      loadingPrice: false,
      priceChange: null,
      requestApproved: null,
      createdByUser: null,
      serviceObj: {},
      bEndEdited: false,
      MIN_VLAN: 2,
      MAX_VLAN: 4093,
      isFetchingDeals: false,
      deals: [],
      showEditIbmModal: false,
    }
  },

  computed: {
    ...mapState('IXTypes', ['ixTypes']),
    ...mapState('Services', ['locations', 'servicesReady']),
    ...mapGetters('ApplicationContext', ['companyContextLoading']),
    ...mapGetters('Auth', ['hasAuth', 'hasFeatureFlag', 'isPartnerAccount']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('IXTypes', ['getIxLocationById']),
    ...mapGetters('Services', ['findPort', 'connectionUidDictionary', 'myConnections', 'myPorts', 'targetPorts', 'getLocationById']),

    // ----- Raw-service-related computed properties -----
    rawService() {
      return deepClone(this.connectionUidDictionary[this.$route.params.serviceId]) || {}
    },
    rawServiceIsIX() {
      return this.rawService.productType === this.G_PRODUCT_TYPE_IX
    },

    // ----- A-End-related computed properties -----
    // Keep a local state of the A-end port (this includes the _location obj).
    aEndPort() {
      if (!this.rawService) return {}

      if (this.rawServiceIsIX) {
        if (!this.rawService.parentPortUid || this.rawService.parentPortUid.length <= 0) return {}

        const portUid = typeof this.rawService.parentPortUid === 'string' ? this.rawService.parentPortUid : this.rawService.parentPortUid[0]

        return this.myPorts.find(port => port.productUid === portUid) || {}

      } else {
        if (!this.rawService.aEnd) return {}

        // Try to get all the details from the public port if available
        const publicPort = this.targetPorts.find(port => port.productUid === this.rawService.aEnd.productUid)

        if (publicPort) return publicPort

        // No? At least we have the location ID in the raw service, so we should be able to give that information.
        const locationInfo = this.locations.find(location => location.id === this.rawService.aEnd.locationId)

        // Use the same format as we would have got from the port
        if (locationInfo) return { _location: locationInfo }

        return {}
      }
    },
    aEndOwned() {
      return this.rawServiceIsIX || this.rawService.aEnd?.ownerUid === this.companyUid
    },
    aEndIsPort() {
      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MEGAPORT
    },
    aEndIsMve() {
      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MVE
    },
    aEndIsMcr() {
      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MCR2
        || this.aEndIsPort
        && this.aEndPort.connectType === 'VROUTER'
    },
    /** Returns an array with the A-End details that are shown in the x-end cards */
    aEndDetailLines() {
      const speed = this.aEndIsMve ? this.aEndMveSizeText : this.aEndPort._speed

      // The API response for IXs doesn't contain aEnd/bEnd objects
      // For consistency across all the different connection types, we use the rawService object
      const locationField = this.rawServiceIsIX
        ? this.rawService?.locationDetail
        : this.rawService?.aEnd?.locationDetail
      const location = `${locationField?.city}, ${locationField?.country}`

      return [
        this.sourceName || this.$t('general.loading'),
        ...(speed ? [speed] : []),
        ...(locationField ? [location] : [this.$t('general.loading')]),
      ]
    },
    aEndMveSizeText() {
      const sizeText = `${this.$t('general.size')}: ${this.aEndPort.mveLabel}`

      return this.aEndIsMve ? sizeText : null
    },

    // ----- B-End-related computed properties -----
    // Keep a local state of the B-end port (this includes the _location obj).
    bEndPort() {
      if (!this.rawService?.bEnd) return {}
      return this.targetPorts.find(port => port.productUid === this.rawService.bEnd.productUid) || {}
    },
    bEndOwned() {
      return this.rawServiceIsIX || this.rawService.bEnd?.ownerUid === this.companyUid
    },
    bEndIsPort() {
      return this.bEndPort.productType === this.G_PRODUCT_TYPE_MEGAPORT
    },
    bEndIsMve() {
      return this.bEndPort.productType === this.G_PRODUCT_TYPE_MVE
        || this.bEndPort.connectType === this.G_PRODUCT_TYPE_MVE
    },
    bEndIsMcr() {
      return this.bEndPort.productType === this.G_PRODUCT_TYPE_MCR2
        || this.bEndPort.connectType === 'VROUTER'
    },
    /** Returns an array with the B-End details that are shown in the x-end cards */
    bEndDetailLines() {
      // For IXs just display the destination name
      if (this.rawServiceIsIX) {
        return [this.destinationName || this.$t('general.loading')]
      }

      const speed = this.bEndIsMve ? this.bEndMveSizeText : this.bEndPort?._speed || ''

      const locationField = this.rawService?.bEnd?.locationDetail
      const location = `${locationField?.city}, ${locationField?.country}`

      return [
        this.destinationName || this.$t('general.loading'),
        ...(speed ? [speed] : []),
        ...(locationField ? [location] : [this.$t('general.loading')]),
      ]
    },
    bEndMveSizeText() {
      if (!this.bEndIsMve || !this.bEndPort.mveLabel) return null

      return `${this.$t('general.size')}: ${this.bEndPort.mveLabel}`
    },

    // ----- Availability/customer-type/permissions-related computed properties -----
    allowNotify() {
      return !this.isPartnerAccount && !this.companyContextLoading
    },
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    editable() {
      return this.nonEditReason == null
    },

    // ----- Tab-related computed properties -----
    allTabs() {
      const tabs = [
        {
          id: 'configure-connection',
          title: this.$t('connections.connection'),
          icon: 'el-icon-edit',
          complete: (() => {
            if (!this.rawService) return false

            if (!this.connectionForm.rateLimit
              || this.connectionForm.rateLimit < this.minRateLimit
              || this.connectionForm.rateLimit > this.maxRateLimit) return false

            if (this.bEndPort?.connectType === 'AZURE'
              && this.connectionForm.bEnd.innerVlan
              && (this.connectionForm.bEnd.innerVlan > this.MAX_VLAN || this.connectionForm.bEnd.innerVlan < this.MIN_VLAN)) {
              return false
            }
            return this.connectionForm.productName && this.connectionForm.rateLimit
          })(),
        },
      ]

      if (this.rawService
        && this.aEndIsMcr
        && this.aEndOwned
        && this.connectionForm.aEndConfig.partnerConfig) {
        tabs.push({
          id: 'configure-aend',
          title: this.$t('general.x-end', { end: 'A' }),
          icon: 'el-icon-edit',
        })
      }

      if (this.rawService
        && this.bEndIsMcr
        && this.bEndOwned
        && this.connectionForm.bEndConfig.partnerConfig
        && this.connectionForm.bEndConfig?.partnerConfig?.connectType !== 'SFDC') {
        tabs.push({
          id: 'configure-bend',
          title: this.$t('general.x-end', { end: 'B' }),
          icon: 'el-icon-edit',
        })
      }

      if (this.rawService && this.bEndPort) {
        if (['AWS', 'AWSHC'].includes(this.bEndPort.connectType)) {
          tabs.push({
            id: 'configure-aws',
            title: 'AWS',
            icon: this.bEndPort.connectType === 'AWS' ? 'el-icon-edit' : 'el-icon-view',
          })
        }
      }

      if (this.rawService && this.bEndPort?.connectType === 'AZURE') {
        tabs.push({
          id: 'configure-azure',
          title: 'Azure',
          icon: 'el-icon-edit',
        })
      }


      if (this.rawService && this.bEndPort?.connectType === 'SFDC') {
        tabs.push({
          id: 'configure-sfdc',
          title: 'Salesforce',
          icon: 'el-icon-edit',
        })
      }

      tabs.push(
        {
          id: 'advanced',
          title: this.$t('general.details'),
          icon: 'el-icon-view',
        },
        {
          id: 'logs',
          title: this.$t('general.logs-label'),
          icon: 'el-icon-view',
        },
        {
          id: 'usage',
          title: this.$t('general.usage-label'),
          icon: 'el-icon-view',
        }
      )

      if (this.showPricing) {
        tabs.push({
          id: 'billing',
          title: this.$t('general.billing'),
          icon: 'el-icon-view',
        })
      }
      return tabs
    },
    currentTab() {
      return this.allTabs[this.tabIndex] || []
    },
    finalTab() {
      return this.allTabs.length === this.tabIndex + 1
    },

    // ----- Rate-limit-related computed properties -----
    minRateLimit() {
      if (this.rawServiceIsIX) {
        return DEFAULT_IX_MIN
      } else {
        return this.rawService.termSpeed ?? minVXCSpeed(this.aEndPort, this.bEndPort)
      }
    },
    maxRateLimit() {
      if (this.rawServiceIsIX) {
        return maxIXSpeed(this.aEndPort, this.ixByLocation)
      } else {
        return this.rawService.maximumRate ?? maxVXCSpeed(this.aEndPort, this.bEndPort) // Fallback to the FE-calculated max speed
      }
    },

    // ----- Other computed properties -----
    parentPort() {
      return this.findPort(this.$route.params.portId)
    },
    sourceName() {
      if (this.serviceObj.productType === this.G_PRODUCT_TYPE_IX) {
        if (!this.aEndPort) return {}
        return this.aEndPort.productName
      }

      if (!this.serviceObj.aEnd && !this.aEndPort.title) return ''
      return this.aEndPort.title || this.serviceObj.aEnd.productName
    },
    destinationName() {
      if (this.serviceObj.productType === this.G_PRODUCT_TYPE_IX) {
        const currentIxType = this.ixTypes[this.serviceObj.locationId]
        if (!currentIxType) {
          this.getIxType(this.serviceObj.locationId)
        } else {
          const currentIxTypeInfo = currentIxType.find(location => location.name === this.serviceObj.networkServiceType)
          if (currentIxTypeInfo) {
            return currentIxTypeInfo.description || currentIxTypeInfo.name
          }
        }
        return this.serviceObj.networkServiceType
      }
      if (!this.serviceObj.bEnd) {
        return ''
      }
      return this.bEndPort.title || this.serviceObj.bEnd.productName
    },
    isOwner() {
      return this.rawService.companyUid === this.companyUid
    },
    ixByLocation() {
      const locationId = this.serviceObj.locationId

      return this.getIxLocationById(locationId).find(ix => ix.name === this.serviceObj.networkServiceType)
    },
    selectedDealUid() {
      const isStandardDeal = !this.deals.find(deal => deal.entityUid === this.rawService.dealUid)

      return isStandardDeal ? 'None' : this.rawService.dealUid || 'None'
    },
    showButtons() {
      return (
        this.currentTab.id === 'configure-connection' ||
        (this.currentTab.id === 'configure-aend' && this.connectionForm.aEndConfig.partnerConfig && this.aEndOwned) ||
        (this.currentTab.id === 'configure-bend' && this.connectionForm.bEndConfig.partnerConfig && this.bEndOwned) ||
        (this.currentTab.id === 'configure-aws' && this.connectionForm.bEndConfig.partnerConfig && this.bEndPort.connectType === 'AWS') ||
        (this.currentTab.id === 'configure-azure' && this.connectionForm.bEndConfig.partnerConfig?.managed) ||
        (this.currentTab.id === 'configure-sfdc' && this.connectionForm.bEndConfig.partnerConfig && this.bEndPort.connectType === 'SFDC')
      )
    },
    loadingText() {
      if (this.requestApproved === true) {
        return this.$t('general.processing-approval')
      } else if (this.requestApproved === false) {
        return this.$t('general.processing-denial')
      } else if (this.isFetchingDeals || this.isLoadingService) {
        return this.$t('general.loading')
      }
      return this.$t('general.saving')
    },
    nonEditReason() {
      if (!this.rawService) return null

      let reason = null

      if (this.rawService.provisioningStatus === this.G_PROVISIONING_LOADING) {
        reason = this.$t('connections.non-edit-reason', { reason: this.$t('services.reloading') })
      } else if (this.rawService.adminLocked) {
        reason = this.$t('services.service-locked')
      } else if (this.rawService.locked) {
        reason = this.$t('services.service-admin-locked')
      } else if (!this.hasAuth('modify_service')) {
        reason = this.$t('services.edit-permission-denied')
      } else if (this.approvalPending.type === 'NEW' && this.approvalPending.internal) {
        if (this.approvalPending.permitted) {
          reason = this.$t('connections.awaiting-approval')
        } else {
          reason = this.$t('connections.non-edit-reason', { reason: this.$t("The connection is pending your company's approval.") })
        }
      } else if (this.approvalPending.type && !this.approvalPending.internal) {
        if (this.approvalPending.type === 'NEW') {
          reason = this.$t('connections.non-edit-reason', {
            reason: this.$t('connections.pending-approval', {
              approvalFrom: this.rawService.vxcApproval.message ? this.rawService.vxcApproval.message : `(${this.$t('general.unknown')})`,
            }),
          })
        }
      } else if (this.rawService.provisioningStatus === this.G_PROVISIONING_DEPLOYABLE) {
        reason = this.$t('connections.non-edit-reason', { reason: this.$t('connections.awaiting-deployment') })
      }

      if (!reason) return null

      const split = reason.split('|')

      let assembledReason = ''

      if (split.length === 2) {
        assembledReason += split[0]
        assembledReason += ' <b>'
        assembledReason += split[1]
        assembledReason += '</b>'
      } else {
        assembledReason = split[0]
      }

      return assembledReason
    },
    iconType() {
      let aEnd = this.G_PRODUCT_TYPE_MEGAPORT

      if (!this.aEndOwned) {
        // Show a generic VXC icon here since we don't have enough info on third party ends
        aEnd = 'VXC'
      } else if (this.aEndIsMve) {
        aEnd = this.G_PRODUCT_TYPE_MVE
      } else if (this.aEndIsMcr) {
        aEnd = 'MCR'
      } else if (this.aEndPort.aggregationId) {
        aEnd = 'LAG'
      }

      let bEnd = this.G_PRODUCT_TYPE_MEGAPORT

      if (!this.bEndOwned && !isCloudConnection(this.bEndPort.connectType)) {
        // Show a generic VXC icon here since we don't have enough info on third party ends
        bEnd = 'VXC'
      } else if (this.serviceObj.productType === this.G_PRODUCT_TYPE_IX) {
        bEnd = this.G_PRODUCT_TYPE_IX
      } else if (this.bEndPort.connectType === 'TRANSIT') {
        bEnd = 'MegaportInternet'
      } else if (this.bEndIsMve) {
        bEnd = this.G_PRODUCT_TYPE_MVE
      } else if (this.bEndIsMcr) {
        bEnd = 'MCR'
      } else if (this.bEndPort.aggregationId) {
        bEnd = 'LAG'
      }

      return { aEnd, bEnd }
    },
    /**
     * Returns true if editing an IBM direct link 1.0 connection
     *
     * Used to show modal when users are editing their old IBM direct link 1.0
     * connection in favour of creating a new IBM direct link 2.0 connection
     */
    oldIbmConnection() {
      const ibmCloudItem = CLOUD_ITEMS.find(item => item.title === 'IBM Cloud')
      return (
        // Checking that the connection is IBM by checking for the Uid
        ibmCloudItem.companyUids.includes(this.serviceObj.bEnd.ownerUid) &&
        // If partnerConfig doesn't exist then the connection is IBM 1.0
        this.connectionForm.bEndConfig.partnerConfig === null
      )
    },
    diversityZones() {
      return {
        aEnd: this.rawService.aEnd?.diversityZone ?? '',
        bEnd: this.rawService.bEnd?.diversityZone ?? '',
      }
    },
  },

  watch: {
    editable() {
      // We don't want to refresh the data if we're editing, but if not editable we should poll for updates still.
      this.setPeriodicRefreshEnabled(!this.editable)
    },
    requestApproved() {
      if (this.requestApproved === true || this.requestApproved === false) {
        this.processApproval()
      }
    },
    bEndPort() {
      this.showPricing && this.getPrice()
    },
    'connectionForm.rateLimit': {
      immediate: true,
      handler() {
        this.showPricing && this.getPrice()
      },
    },
    // This handles page refresh and ensuring data is fetched from /v2/product/$uuid
    servicesReady() {
      this.setup()
    },
    rawService: {
      immediate: true,
      deep: true,
      handler(newConnection, oldConnection) {
        const validNewConnection = newConnection && !isEmpty(newConnection)
        const validOldConnection = oldConnection && !isEmpty(oldConnection)

        if (validOldConnection && !validNewConnection) {
          const props = {
            title: this.$t('connections.deleted'),
            message: this.$t('services.service-deleted'),
            type: 'error',
            duration: 3000,
          }

          this.allowNotify && this.$notify(props)

          this.$router.push(resolveServicesPage())
        } else if (!validOldConnection && validNewConnection) {
          // This could happen if the user reloaded and when the page loaded the services weren't there
          this.initForm()
        } else if (validOldConnection && validNewConnection) {
          if (!this.formInitialised) {
            this.initForm()
          } else if (!this.connectionFormEditing) {
            // The data has been edited outside of us. See if any form values have been changed
            // Note that the resources key will pick up all the MCR, AWS, Azure etc config items
            const matchingFields = [
              'productName',
              'secondaryName',
              'rateLimit',
              'aEnd.vlan',
              'bEnd.vlan',
              'aEnd.innerVlan',
              'bEnd.innerVlan',
              'aEnd.vNicIndex',
              'bEnd.vNicIndex',
              'vlan',
              'macAddress',
              'publicGraph',
              'ixPeerMacro',
              'reverseDns',
              'costCentre',
              'dealUid',
              'resources',
              'asn',
              'password',
            ]

            const oldFields = deepClone(pick(oldConnection, matchingFields))
            const newFields = deepClone(pick(newConnection, matchingFields))

            // We have a problem with resources.interface, since it can be in a different order on different calls.
            const compareInterfaces = (a, b) => {
              if (a.id > b.id) return 1
              if (a.id < b.id) return -1
              return 0
            }

            if (Array.isArray(oldFields.resources?.interface)) {
              oldFields.resources.interface.sort(compareInterfaces)
            }

            if (Array.isArray(newFields.resources?.interface)) {
              newFields.resources.interface.sort(compareInterfaces)
            }

            if (stableStringify(oldFields) !== stableStringify(newFields) && !this.connectionFormEditing) {
              this.initForm()
            }
          }
        }

        this.updateApprovalPending()
      },
    },
  },

  created() {
    this.setup()
  },

  beforeDestroy() {
    // Before we leave, ensure we trigger polling again.
    this.setPeriodicRefreshEnabled(true)
  },


  mounted() {
    // Determine whether the ASN field is required or not
    let setting = true

    if (['AWS', 'AWSHC', 'SFDC'].includes(this.bEndPort.connectType) && this.aEndPort.virtual) {
      setting = false
    }

    this.connectionRules['bEndConfig.partnerConfig.asn'].required = setting
  },

  methods: {
    ...mapActions('IXTypes', ['getIxType']),
    ...mapActions('Services', ['fetchServiceOrConnection', 'removeServiceOrConnection', 'getMyServices', 'addService', 'editConnection', 'setPeriodicRefreshEnabled']),
    ...mapActions('Notifications', ['showIbmReminderModal']),
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),
    resolveServicesPage,
    scopedPriceBook,

    // ----- Validation-related methods -----
    validateVlan(rule, value, callback) {
      if (this.bEndPort?.connectType === 'AZURE') {
        this.validateAzureInnerVlan(rule, value, callback)
        return
      }
      // For this to work the field name must match the ref
      const vlanComponent = this.$refs.connectionConfig.$refs[rule.field]
      if (!vlanComponent) {
        captureSentryError(new Error('Field name must match ref'))
        callback('false')
        return
      }

      // Make sure the vlan is checked even if the user hasn't typed in it
      if (vlanComponent.fresh) {
        vlanComponent.triggerValidation()
      }

      if (vlanComponent.vlanValid) {
        callback()
      } else if (vlanComponent.checkingVlan) {
        vlanComponent.$on('vlanCheckComplete', () => {
          if (vlanComponent.vlanValid) {
            callback()
          } else {
            callback('false')
          }
          vlanComponent.$off('vlanCheckComplete')
        })
      } else {
        callback('false')
      }
    },
    validateBEndAsn(rule, value, callback) {
      // Special case for AWS as per ENG-8123
      if (this.bEndPort.connectType === 'AWS') {
        validateAwsAsn(rule, value, callback)
        // TODO: Check if a special case is needed here for 'AMSIX' the same way as in CreateConnection
      } else {
        validateAsnDefault(rule, value, callback)
      }
    },
    validateAzureInnerVlan(rule, value, callback) {
      if (!rule.required) {
        callback()
        return true
      }
      const vlan = Number.parseInt(value)
      if (Number.isNaN(vlan) || !vlan) {
        callback(this.$t('validations.vlan-required'))
      } else if (vlan < this.MIN_VLAN || vlan > this.MAX_VLAN) {
        callback(this.$t('validations.vlan-range', { minVlan: this.MIN_VLAN, maxVlan: this.MAX_VLAN }))
      } else {
        callback()
      }
    },
    validateRateLimit(_rule, value, callback) {
      // Special case for AWS where the user hasn't changed the speed
      // and it's higher than the new allowable speed (see ENG-8121 for details)
      if (
        !this.validatingForSave &&
        value === this.rawService.rateLimit &&
        ['AWS', 'AWSHC'].includes(this.bEndPort?.connectType) &&
        value > this.maxRateLimit
      ) {
        callback()
      } else if (value < this.minRateLimit || value > this.maxRateLimit) {
        callback(this.$t('validations.mbps-value-range', { min: this.minRateLimit, max: this.maxRateLimit }))
      } else if (!/^\d+$/.test(value)) {
        callback(this.$t('validations.value-integer'))
      } else {
        callback()
      }
    },

    // ----- Tab-related methods -----
    gotoTab(tabNumber) {
      this.tabIndex = tabNumber
    },
    goToNextTab() {
      this.gotoTab(this.tabIndex + 1)
    },
    goToLastTab() {
      this.gotoTab(this.tabIndex - 1)
    },

    // ----- Pricing-related methods -----
    getPrice() {
      this.priceStore = false

      if (!this.serviceObj.productType) return this.priceStore

      // We don't want to display the price if we're the B-End party - no B-End charges for the service
      if (this.serviceObj.aEnd.ownerUid !== this.serviceObj.bEnd.ownerUid && this.serviceObj.bEnd.ownerUid === this.companyUid)
        return false

      if (this.serviceObj.productType === this.G_PRODUCT_TYPE_IX) {
        this.loadingPrice = true

        if (!this.serviceObj.networkServiceType || !this.serviceObj.locationId) return false

        // Calculate the price for the IX service
        this.scopedPriceBook()
          .ix(
            this.serviceObj.networkServiceType,
            this.serviceObj.locationId,
            this.connectionForm.rateLimit,
            // this.serviceObj.contractTermMonths, // Uncomment when term functionality has been extended to IXs
            this.serviceObj.buyoutPort,
            this.serviceObj.productUid
          )
          .then(res => {
            this.priceStore = res
            /** START **/
            // Part of a workaround to display the current recurring monthly rate in PriceChange (see ENG-21893)
            // API: We need GET /v2/product/:productUid/rating/:year/:month?newSpeed=<speed> to include the current recurring monthly rate (see ENG-21967)
            // TODO: Remove this as part of the necessary refactor in ENG-21968
            if (this.priceStore && !this.currentMonthlyPriceCaptured) {
              this.currentMonthlyPrice = this.priceStore.monthlyRate

              this.currentMonthlyPriceCaptured = true
            }
            /** END **/

          })
          .catch(() => {
            // Error handled by the pricebook module.
            this.priceStore = false
          })
          .finally(() => {
            this.loadingPrice = false
          })

        return this.priceStore
      }

      if (!this.serviceObj.aEnd.productUid || !this.serviceObj.bEnd.productUid || !this.connectionForm.rateLimit) {
        return false
      }

      // Calculate the price for the VXC service
      const aLocationId = this.serviceObj.aEnd.locationId || this.aEndPort.locationId
      const bLocationId = this.serviceObj.bEnd.locationId || this.bEndPort.locationId

      this.loadingPrice = true

      this.scopedPriceBook()
        .vxc(
          aLocationId,
          bLocationId,
          this.connectionForm.rateLimit,
          this.serviceObj.contractTermMonths,
          this.aEndPort.productType,
          this.bEndPort.connectType, // This has a fallback value of 'DEFAULT'
          this.aEndPort.buyoutPort,
          this.serviceObj.productUid
        )
        .then(res => {
          this.priceStore = res
          /** START **/
          // Part of a workaround to display the current recurring monthly rate in PriceChange (see ENG-21893)
          // API: We need GET /v2/product/:productUid/rating/:year/:month?newSpeed=<speed> to include the current recurring monthly rate (see ENG-21967)
          // TODO: Remove this as part of the necessary refactor in ENG-21968
          if (this.priceStore && !this.currentMonthlyPriceCaptured) {
            this.currentMonthlyPrice = this.priceStore.monthlyRate

            this.currentMonthlyPriceCaptured = true
          }
          /** END **/
        })
        .catch(() => {
          // Error handled by the pricebook module.
          this.priceStore = false
        })
        .finally(() => {
          this.loadingPrice = false
        })
    },
    acceptPriceChange() {
      /** START **/
      // Part of a workaround to display the current recurring monthly rate in PriceChange (see ENG-21893)
      // API: We need GET /v2/product/:productUid/rating/:year/:month?newSpeed=<speed> to include the current recurring monthly rate (see ENG-21967)
      // TODO: Remove this as part of the necessary refactor in ENG-21968
      this.currentMonthlyPrice = this.priceStore.monthlyRate
      /** END **/

      if (this.approvalPending.type && this.approvalPending.internal) {
        // if the price change is accepted while there is a pending approval, approve the connection
        this.processApproval()
      } else {
        // accept the price change and continue with the save
        this.saveUpdatedData()
      }
    },
    cancelPriceChange() {
      this.saveConnectionFailed = true
      if (this.approvalPending.type && this.approvalPending.internal) {
        // if the price change popup is cancelled while there is a pending approval, deny the connection approval
        this.requestApproved = false
        this.processApproval()
      } else {
        // dismiss the price change window and leave the wf open to allow further changes
        this.priceChange = null
      }
    },
    fetchDeals() {
      this.savingConnection = true
      this.isFetchingDeals = true
      const companyUid = this.companyUid || ''
      let defaultDeal = {
        entityUid: 'None',
        dealId: '',
        opportunityName: 'None',
      }
      sdk.instance
        .partnerVantage()
        .deals(companyUid, { includeInvalidDeals: true })
        .then(res => {
          if (res.length) {
            const isStandardDeal = !res.find(deal => deal.entityUid === this.rawService.dealUid)
            // if the dealUid does not match other deal Uid then, it is a standard deal
            if (isStandardDeal) {
              defaultDeal.entityUid = this.rawService.dealUid || 'None'
            }
            this.deals = [
              defaultDeal,
              ...res,
            ]
            // sort in ascending by deal id
            this.deals = this.deals.sort((a, b) => a.dealId.toUpperCase().localeCompare(b.dealId.toUpperCase()))
          } else {
            this.deals = [defaultDeal]
            this.connectionForm.dealUid = 'None'
          }
        })
        .catch(e => captureSentryError(e))
        .finally(() => {
          this.savingConnection = false
          this.isFetchingDeals = false
        })
    },

    // ----- Other methods -----
    setup() {
      // This will fail if services aren't loaded
      if (!this.servicesReady) return

      this.isLoadingService = true

      this.fetchDeals()

      // Refresh the data for the service when we initially load
      this.fetchServiceOrConnection({
        productUid: this.rawService.productUid || this.$route.params.serviceId,
        incResources: true,
        periodic: false,
      }).then(() => {
        if (this.deals.length) {
          this.initForm()
        }
      }).catch(() => {
        // empty function is intentional
      }).finally(() => {
        this.isLoadingService = false
      })
    },
    initForm() {
      if (this.rawService.productUid) {
        this.formInitialised = true
      }

      // Just some protection in case the user tries to change URL on us.
      if (this.rawService.provisioningStatus === this.G_PROVISIONING_DESIGN) {
        return this.$router.push(`/create-connection/${this.rawService.productUid}`, () => {
          // empty function is intentional
        })
      }

      // Set up the basic connection information
      this.connectionForm = {
        productName: this.rawService.productName,
        secondaryName: this.rawService.secondaryName,
        asn: this.rawService.asn,
        password: this.rawService.password,
        costCentre: this.rawService.costCentre || null,
        dealUid: this.selectedDealUid,
        rateLimit: this.rawService.rateLimit,
        // We have to check if the aEnd exists because IXs come without this object from the API
        aEnd: {
          vlan: this.rawService.aEnd ? this.rawService.aEnd.vlan : null,
          innerVlan: this.rawService.aEnd ? this.rawService.aEnd.innerVlan : null,
          vNicIndex: this.rawService.aEnd ? this.rawService.aEnd.vNicIndex || 0 : null,
        },
        // We have to check if the bEnd exists because IXs come without this object from the API
        bEnd: {
          vlan: this.rawService.bEnd ? this.rawService.bEnd.vlan : null,
          innerVlan: this.rawService.bEnd ? this.rawService.bEnd.innerVlan : null,
          vNicIndex: this.rawService.bEnd ? this.rawService.bEnd.vNicIndex || 0 : null,
        },
        vlan: this.rawService.vlan,
        macAddress: this.rawService.macAddress,
        publicGraph: this.rawService.publicGraph,
        ixPeerMacro: this.rawService.ixPeerMacro || null,
        reverseDns: this.rawService.reverseDns || null,
        aEndConfig: {
          partnerConfig: null,
        },
        bEndConfig: {
          partnerConfig: null,
        },
        shutdown: false,
      }

      // Massage the VLAN data for untagged VLANs
      if (this.connectionForm.aEnd.vlan === null) {
        this.connectionForm.aEnd.vlan = -1
      }

      if (this.connectionForm.bEnd.vlan === null) {
        this.connectionForm.bEnd.vlan = -1
      }

      if (this.rawServiceIsIX && this.connectionForm.vlan === null) {
        this.connectionForm.vlan = -1
      }

      // Azure innerVlan value rule update
      if (this.rawService.resources?.csp_connection?.connectType === 'AZURE' && this.connectionForm.bEnd.innerVlan) {
        this.connectionRules['bEnd.innerVlan'].required = true
      }
      // If no secondary name, populate it with the productName by default
      this.connectionForm.secondaryName = this.connectionForm.secondaryName || this.connectionForm.productName

      // Collect the information needed for MCR configurations from the resources
      // If individual ends.

      // If they are reloading the page it is possible that the resources haven't loaded yet, so
      // handle it, and it will be picked up from the watcher, later
      if (this.rawService.resources) {
        if (this.rawService.resources.csp_connection?.resource_name === 'a_csp_connection') {
          this.connectionForm.aEndConfig.partnerConfig = this.rawService.resources.csp_connection
        }

        if (this.rawService.resources.csp_connection?.resource_name === 'b_csp_connection') {
          this.connectionForm.bEndConfig.partnerConfig = this.rawService.resources.csp_connection
          const { amazonAsn = null } = this.connectionForm.bEndConfig.partnerConfig

          if (amazonAsn) {
            this.connectionForm.bEndConfig.partnerConfig.amazonAsn = amazonAsn.toString()
          }
        }

        // Or both ends
        if (
          this.rawService.resources.csp_connection &&
          Array.isArray(this.rawService.resources.csp_connection) &&
          this.rawService.resources.csp_connection.length
        ) {
          const findA = this.rawService.resources.csp_connection.find(e => e.resource_name === 'a_csp_connection')
          const findB = this.rawService.resources.csp_connection.find(e => e.resource_name === 'b_csp_connection')

          if (findA) {
            this.connectionForm.aEndConfig.partnerConfig = findA
          }

          if (findB) {
            const { amazonAsn = null } = findB
            this.connectionForm.bEndConfig.partnerConfig = findB

            if (amazonAsn) {
              this.connectionForm.bEndConfig.partnerConfig.amazonAsn = amazonAsn.toString()
            }
          }
        }

        // Grab the shutdown state
        const shutdownResource = this.rawServiceIsIX ? 'vpls_interface' : 'vll'
        this.connectionForm.shutdown = this.rawService.resources[shutdownResource]?.shutdown || false
      }

      // The MCR config needs to have an enhanced model of the service,
      // so we will make a copy of the service and add the extra data:
      const cloned = deepClone(this.rawService)

      // So IXs can be treated the same as everything else
      cloned.aEnd ||= {}
      cloned.bEnd ||= {}

      cloned.aEnd.partnerConfig = { connectType: this.aEndPort.connectType }
      cloned.bEnd.partnerConfig = { connectType: this.bEndPort.connectType }

      Vue.set(this, 'serviceObj', cloned)

      // Show modal letting users know about the new IBM API integrations
      if (this.oldIbmConnection) this.showEditIbmModal = true
    },
    /**
     * Switch the connectionFormEditing flag
     */
    switchConnectionFormEditing() {
      this.connectionFormEditing = true
    },
    /**
     * Update handler for MCR Config component
     * @param {Object} configuration A/B End partnerConfig
     * @param {String} end connectionForm switch aEndConfig or bEndConfig
     */
    updateMcrConfig(configuration, end) {
      // set flag to prevent polling updates from blowing away connection form edits
      this.connectionFormEditing = true

      let update = { ...this.connectionForm }
      let config = { ...this.connectionForm[end] }

      config.partnerConfig = { ...configuration.partnerConfig }
      update[end] = config

      this.connectionForm = { ...update }
    },
    processApproval() {
      let text = ''
      let title = ''

      if (this.requestApproved) {
        text = this.$t('connections.approve-question')
        title = this.$t('connections.approve-title')
      } else if (this.approvalPending.internal && this.approvalPending.permitted) {
        text = this.$t('connections.deny-question')
        title = this.$t('connections.deny-title')
      } else {
        text = this.$t('connections.cancel-question')
        title = this.$t('connections.cancel-title')
      }

      this.$confirm(text, title, {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
      })
        .then(() => {
          this.savingConnection = true

          sdk.instance
            .approveVxc(this.approvalPending.approveUid, {
              isApproved: this.requestApproved,
              vlan: this.serviceObj.bEnd.vlan || this.serviceObj.bEnd.innerVlan,
            })
            .then(() => {
              this.savingConnection = false

              if (this.approvalPending.type === 'SPEED_CHANGE') {
                let speedChangeTitle = null
                let speedChangeMessage = null

                if (this.requestApproved) {
                  speedChangeTitle = this.$t('connections.change-approved-title')
                  speedChangeMessage = this.$t('connections.change-approved-message')
                } else if (this.approvalPending.internal) {
                  speedChangeTitle = this.$t('connections.change-denied-title')
                  speedChangeMessage = this.$t('connections.change-denied-message')
                } else {
                  speedChangeTitle = this.$t('connections.change-cancelled-title')
                  speedChangeMessage = this.$t('connections.change-cancelled-message')
                }

                this.notifyGood({
                  title: speedChangeTitle,
                  message: speedChangeMessage,
                })
              } else {
                this.notifyGood({
                  title: this.requestApproved ? this.$t('connections.approved-title') : this.$t('connections.denied-title'),
                  message: this.$t(this.requestApproved ? 'connections.connection-approved' : 'connections.connection-denied', {
                    destination: this.approvalPending.message || `(${this.$t('general.unknown')})`,
                  }),
                })
              }

              this.$nextTick(() => {
                if (!this.requestApproved && this.approvalPending.type === 'NEW') {
                  // Denied request should have removed the service
                  this.removeServiceOrConnection({ productUid: this.serviceObj.productUid, cartCleanup: false })
                } else {
                  this.approvalPending.type = null

                  this.fetchServiceOrConnection({ productUid: this.serviceObj.productUid })
                    .then(() => {
                      // empty function is intentional
                    })
                    .catch(() => {
                      // empty function is intentional
                    })
                }

                this.getMyServices()
                this.requestApproved = null
              })
            })
            .catch(e => {
              this.savingConnection = false
              this.requestApproved = null

              if (!e.handled) {
                const errorStr = e.message || e

                this.notifyBad({
                  title: this.$t(this.requestApproved ? 'connections.approval-error' : 'connections.deny-error'),
                  message: this.$t('connections.approval-issues', { action: this.requestApproved ? this.$t('connections.approving') : this.$t('connections.denying'), errorStr }),
                })
              }
            })
        })
        .catch(() => {
          this.requestApproved = null
        })
    },
    updateApprovalPending() {
      if (!this.rawService || !this.rawService.vxcApproval || !this.rawService.vxcApproval.status) {
        this.approvalPending.type = null
        return
      }
      const approval = this.rawService.vxcApproval
      const internal = this.rawService.vxcApproval.status === this.G_PROVISIONING_PENDING_INTERNAL
      Object.assign(this.approvalPending, {
        internal,
        type: approval.type,
        newSpeed: approval.newSpeed,
        oldSpeed: this.rawService.rateLimit,
        permitted: internal ? this.hasAuth('approve_order') : this.hasAuth('place_order'),
        approveUid: approval.uid,
        message: approval.message,
      })
    },
    saveConnection() {
      this.saveConnectionFailed = false

      if (!this.editable) {
        // Don't even attempt to commit changes if the service is not editable
        this.saveConnectionFailed = true

        return
      }

      // TODO: Check whether this is actually required - it should come through correctly in the data.
      if (!this.serviceObj.aEnd.locationId && this.serviceObj.productType !== this.G_PRODUCT_TYPE_IX) {
        this.serviceObj.aEnd.locationId = this.findPort(this.serviceObj.aEnd.productUid).locationId
      }

      if (!this.serviceObj.bEnd.locationId && this.serviceObj.productType !== this.G_PRODUCT_TYPE_IX) {
        this.serviceObj.bEnd.locationId = this.findPort(this.serviceObj.bEnd.productUid).locationId
      }

      this.validatingForSave = true

      this.$refs.connectionForm.validate(valid => {
        this.validatingForSave = false

        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }

          this.$notify(props)

          this.saveConnectionFailed = true

          return
        }

        // Since it is now possible for users to have gone to the AWS tab without having edited the rate limit
        // bypassing the normal validation, and for that rate limit to be too high, and now they have edited
        // something on the AWS screen and want to save, we need to do an additional manual check on the rate
        // limit. See ENG-8121 for details.
        if (
          this.connectionForm.rateLimit === this.rawService.rateLimit &&
          ['AWS', 'AWSHC'].includes(this.bEndPort?.connectType) &&
          this.connectionForm.rateLimit > this.maxRateLimit
        ) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.rate-limit'),
            type: 'error',
            duration: 3000,
          }

          this.$notify(props)

          this.saveConnectionFailed = true

          // Switch them back to the page with the rate limit on it
          this.tabIndex = 0

          return
        }

        this.savingConnection = true

        // Only do the price check if you are the A-End owner
        if (this.showPricing &&
          this.aEndOwned &&
          this.rawService.rateLimit !== this.connectionForm.rateLimit) {
          sdk.instance
            .product(this.serviceObj.productUid)
            .history(DateTime.now().year, DateTime.now().month, this.connectionForm.rateLimit)
            .then(data => {
              this.savingConnection = false

              if (data.delta === 0) {
                this.saveUpdatedData()
              } else if (this.disabledFeatures.showPrices) {
                this.saveUpdatedData() // If we are not showing prices, we don't want to ask about a price change.
              } else if (!this.hasAuth('place_order')) {
                const props = {
                  title: this.$t('validations.no-approval-price-title'),
                  message: this.$t('validations.no-approval-price-message'),
                  duration: 5000,
                }

                this.$notify.error(props)
              } else {
                this.priceChange = data
              }
            })
            .catch(err => {
              this.notifyBad({
                title: this.$t('pricebook.failed', { product: this.connectionForm.productName }),
                message: get(err, 'data.message'),
              })

              this.savingConnection = false
            })
        } else {
          this.saveUpdatedData()
        }
      })
    },
    saveUpdatedData() {
      // This should hide the popup
      this.priceChange = null
      this.savingConnection = true

      // Start with the service object that we created from the raw service
      const connection = deepClone(this.serviceObj)

      // Then apply the settings from the connection form (updated data).
      // We need to be careful with the end VLANs, so copy the ends across first.
      Object.assign(connection.aEnd, this.connectionForm.aEnd)
      Object.assign(connection.bEnd, this.connectionForm.bEnd)

      // Add the productType and connectType for both ends so we can use them when processing updates
      connection.aEnd.productType = this.aEndPort.productType
      connection.bEnd.productType = this.bEndPort.productType
      connection.aEnd.connectType = this.aEndPort.connectType
      connection.bEnd.connectType = this.bEndPort.connectType

      const formBits = { ...this.connectionForm }

      delete formBits.aEnd
      delete formBits.bEnd

      // If the user hasn't edited the reverseDns, then don't send it through
      // This is because the API will reject it if it is the same as the existing value, as the default contains megaport.com which is not allowed.
      if (formBits.reverseDns === connection.reverseDns) {
        delete formBits.reverseDns
        delete connection.reverseDns
      }

      Object.assign(connection, formBits)

      // And A and B end partner configs for MCR
      if (this.connectionForm.aEndConfig.partnerConfig && connection.aEnd.partnerConfig) {
        Object.assign(connection.aEnd.partnerConfig, this.connectionForm.aEndConfig.partnerConfig)
      }

      if (this.connectionForm.bEndConfig.partnerConfig && connection.bEnd.partnerConfig) {
        Object.assign(connection.bEnd.partnerConfig, this.connectionForm.bEndConfig.partnerConfig)
      }

      // If they haven't edited the AWS settings, then make sure we don't send the config through.
      if ((this.bEndPort.connectType === 'AWS' || this.bEndPort.connectType === 'AWSHC') && !this.bEndEdited) {
        connection.bEnd.partnerConfig = null
      }

      let hasAEndConfig = false
      let hasBEndConfig = false
      let hasAwsConfig = false
      let hasAzureConfig = false
      let hasSfdcConfig = false

      for (const tab of this.allTabs) {
        if (tab.id === 'configure-aend') {
          hasAEndConfig = true
        } else if (tab.id === 'configure-bend') {
          hasBEndConfig = true
        } else if (tab.id === 'configure-azure') {
          hasAzureConfig = true
        } else if (tab.id === 'configure-aws') {
          hasAwsConfig = true
        } else if (tab.id === 'configure-sfdc') {
          hasSfdcConfig = true
        }
      }

      if (!hasAEndConfig) {
        delete connection.aEndConfig
        connection.aEnd.partnerConfig = null
      }

      if (!hasBEndConfig && !hasAwsConfig && !hasAzureConfig && !hasSfdcConfig) {
        delete connection.bEndConfig
        connection.bEnd.partnerConfig = null
      }

      if (connection.productType === 'IX' && connection.asn) {
        connection.asn = connection.asn.toString().replace(/\D/g, '')
      }

      this.editConnection({ connection })
        .then(() => {
          this.savingConnection = false

          // Check if edited connection is IBM and display modal reminding users to confirm connection in IBM portal
          if (this.connectionForm.bEndConfig.partnerConfig.csp_name === 'IBM') {
            this.showIbmReminderModal()
          }
        }).catch(() => {
          this.savingConnection = false
          this.saveConnectionFailed = true
        })
    },
    azurePeering(value) {
      if (this.bEndPort?.connectType === 'AZURE') {
        this.connectionRules['bEnd.innerVlan'].required = value
        this.connectionForm.bEnd.innerVlan = null
      }
    },
    handleCloseBtn() {
      if (history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push(resolveServicesPage())
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  padding-top: 1rem;
  background-color: var(--mp-sub-header-background-color);
  border-bottom: 1px solid var(--card-border-color);
}
.steps {
  padding: 1rem;
}

.disabled svg.icon {
  color: var(--font-color-disabled-base);
}

svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
  &.transfer {
    margin: 1.5rem;
  }
}

.price-box {
  margin: auto;
  margin-bottom: 1rem;
  width: fit-content;
  height: 2rem;
  color: var(--color-text-regular);
  font-size: 1.4rem;
  font-weight: 300;
}

.content-panel {
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 3rem;
  margin: auto;
  width: fit-content;
  max-width: 1500px;
  min-width: 300px;
  &.full-size {
    width: unset;
  }
}

.non-editable {
  background-color: var(--color-white);
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 1rem 2rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 2rem;
  line-height: normal;
}

.max-width-720px {
  max-width: 720px;
}
</style>

<style lang="scss">
.connection-details .el-step__icon.is-icon {
  background-color: var(--mp-sub-header-background-color);
}
</style>
